import React, {useState, useRef, useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    CrossIcon, DigitalPen, DownArrow, EditIcon, HorizontalArrow, SendIcon, IntakeFormIcon, NewMessageIcon,
} from "../../Components/Icons";
import {
    getPayerUserForAdminLookup,
    getPayerUserByNonuniqueIdentifier,
    redirectPayUserForAdminLookup,
    deleteUploadedDoc,
    getAdminUserData,
    saveAfterCreateTemplate,
    getMessage,
    userFormDelete,
    getHelloSignTemplate,
    sendMessageSuccessNotify,
    setTempIntakeformId,
    uploadMultipleFiles,
    savePaymentsDataAdmin,
    sendMesssageToUser,
    adminHardDeleteMessage,
    getUserforms,
    downloadSignedForm,
} from "../../Actions/authActions";
import classNames from "classnames";
import map from "lodash/map";
import get from "lodash/get";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import CustomToolTip from "../../Components/CustomToolTip";
import validator from "validator";
import store from "../../Redux/store";
import useForceUpdate from "use-force-update";
import ReplyMessagePopup from "../../Components/ReplyMessagePopup";
import FindPayer from "../../Components/FindPayer/findPayer.js";
import CustomPopover from "../../Components/CustomPopover";
import CustomPayerListPopup from "../../Components/CustomPayerListPopup.js";
import Socket from "../../socketHelper";

const AdminHome = (props) => {
    const {
        subdomain,
        userAdmin,
        fileDrop,
        dragOver,
        dragLeave,
        dragDoc,
        dragFile,
        intakePopUp,
        popUpMessage,
        setIntakePopUp,
        setPopUpMessage,
        setShowPopUp,
        showPopUp,
        themeColor1,
        themeColor3,
        setDragDoc,
        setDragFile,
        payerUser,
        payerEmail,
        setPayerEmail,
        payerName,
        setPayerName,
        setPayerCell,
        payerCell,
        currentPage,
    } = props;

    const dispatch = useDispatch();
    const inputFile = useRef(null);

    const navigate = useNavigate();
    const storeInstance = store.getInstance();
    const forceUpdate = useForceUpdate();

    const userForms = useSelector((state) => state.auth.userForms);

    const isSendMessageSuccess = useSelector((state) => state.auth.sendMessageSuccess);
    const messages = useSelector((state) => state.auth.messages);
    const [patientNumber, setPatientNumber] = useState("");
    const [chosenPayer, setChosenPayer] = useState(null);
    // const [patientPayerId, setPatientPayerId] = useState("");
    const [isTemp, setIsTemp] = useState("");
    const [isSearch, setIsSearch] = useState({});
    const [isSearchById, setIsSearchById] = useState({});
    const [errorMsgPatient, setErrorMsgPatient] = useState("");
    const [errorMsgEmail, setErrorMsgEmail] = useState("");
    const [sendBy, setSendBy] = useState("");
    const [popoverTitle, setPopoverTitle] = useState("Are you sure you want to remove this?");
    const [formIndex, setFormIndex] = useState("");
    const [isOpenEditIcon, setIsOpenEditIcon] = useState(false);
    const [whatToEdit, setWhatToEdit] = useState(false);
    const [editHelloSignUrls, setEditHelloSignUrls] = useState("");
    const [showHelloSign, setShowHelloSign] = useState(false);
    const [target, setTarget] = useState(null);
    const [docId, setDocId] = useState("");
    const [docType, setDocType] = useState("");
    const [cardId, setCardId] = useState("");
    const [msgDataDelete, setMsgDataDelete] = useState({});
    const [userFormID, setUserFormID] = useState("");
    const [isOpenReply, setIsOpenReply] = useState(false);
    const [msgConvo, setMsgConvo] = useState({})
    const [sendMsg, setSendMsg] = useState("");
    const [messageId, setMessageId] = useState("");
    const [sendLoader, setSendLoader] = useState(false);
    const [userId, setUserId] = useState("");
    const [messageIndex, setMessageIndex] = useState(null);
    const [replyToMessageIndex, setReplyToMessageIndex] = useState(null);
    const [isLoad, setIsLoad] = useState(false);

    const [helloSignLoader, setHelloSignLoader] = useState(false);
    const [getStartedDoc, setGetStartedDoc] = useState(false);
    const [getStartedIntake, setGetStartedIntake] = useState(false);
    const [errorReplyToAdmin, setErrorReplyToAdmin] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [isOpenPayerList, setIsOpenPayerList] = useState(false);
    const [isOpenPayerListMessage, setIsOpenPayerListMessage] = useState(false);
    const [payerList, setPayerList] = useState([]);
    const [showNewMessagePopup, setShowNewMessagePopup] = useState(false);

    const [isOpenInTakeSuccessful, setIsOpenInTakeSuccessful] = useState(false);
    const [newMessageIconParentHover, setNewMessageIconParentHover] = useState(false);
    const [isReply, setIsReply] = useState(false);

    const [fileToEdit, setFileToEdit] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(null);

    useEffect(() => {
        if (Object.keys(userAdmin).length && currentPage === "adminDashboard") {
            dispatch(getMessage(subdomain));
            dispatch(getUserforms(subdomain));
        }
    }, []);

    useEffect(() => {
        if (Socket) {
            Socket.on(`sendReplyToAdmin_${subdomain}`, () => {
                dispatch(getMessage(subdomain));
            });
        }
    }, []);

    useEffect(() => {
        if (messages) {
            setIsSearch(messages);
            setIsSearchById(messages);
            // setMsgConvo(messages);
        }
    }, [messages]);

    // const handleMessagePopUp = (event, data) => {
    //   setShowPopUp(!showPopUp);
    //   setTarget(event.target);
    //   setMsgDataDelete(data);
    //   setPopoverTitle("Are you sure you want to remove this conversation?");
    // };

    const toggleReplyPopup = (isOpenReply) => {
        setIsOpenReply(!isOpenReply);
    };

    const handleRemoveId = (e) => {
        setIsTemp(e.target.value);
    };

    // const handleFilterById = (e) => {
    //     if (e.target && e.target.value && e.target.value.trim("") === "") {
    //         if (isSearchById.length < isSearch.length) {
    //             handleClear();
    //         }
    //     }
    //     ;const searchType = classifySearch(e);
    //     const searchMap = {
    //         email: "payerEmail", phone: "payerCell",
    //     };
    //     const filtered = isSearch.filter((val) => {
    //         if (searchType === "name") {
    //             return ((`${val.userId.payerFirst} ${val.userId.payerLast}`).search(e.target.value.toString().trim("")) !== -1) || (val.name.search(e.target.value.toString().trim("")) !== -1);
    //         } else {
    //             return val.userId[searchMap[searchType]] === e.target.value.toString().trim("");
    //         }
    //
    //     });
    //
    //     setIsSearchById(filtered);
    //
    // };

    const handleFilterById = (e) => {
        const filtered = isSearch.filter((val) => {
            const searchableValues = [`${val.userId.payerFirst} ${val.userId.payerLast}`, val.name, val.userId.payerEmail, val.userId.payerCell];
            return searchableValues.some(searchVal => searchVal.search(e.target.value.toString().trim("")) !== -1);
        });
        setIsSearchById(filtered);
    };

    const handleClear = () => {
        setIsTemp("");
        setIsSearchById(isSearch);
    };

    // const handlePatientForm = (event, formId) => {
    //   setShowPopUp(!showPopUp);
    //   setTarget(event.target);
    //   setUserFormID(formId);
    //   setPopoverTitle("Are you sure you want to remove this submission?");
    // };

    // const patientPayerHandler = (e) => {
    //   const test = (e.target && e.target.value) || "";
    //   if (test) {
    //     setPatientNumber(e.target.value);
    //   } else {
    //     setErrorMsgPatient("Patient number is required");
    //   }
    // };

    const handleKeyPress = (e, submitFunction) => {
        if (e.key === "Enter") {
            const test = (e.target && e.target.value) || "";
            if (test) {
                submitFunction(e);
            } else {
                setErrorMsgEmail("Payer email or phone is required.");
                setErrorMsgPatient("Patient number is required");
            }
        }
    };

    const patientChangeHandler = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value.trim("")) {
            if (e.target.value === "" || re.test(e.target.value)) {
                setPatientNumber(e.target.value);
                setErrorMsgPatient("");
            } else {
                setErrorMsgPatient("Invalid Patient Id");
            }
        } else {
            setErrorMsgPatient("Patient Number field is empty");
        }
    };

    const handleClick = (e) => {
        e.target.select();
    };

    const payerEmailhandler = (e) => {
        const test = (e.target && e.target.value).toLowerCase() || "";
        if (!test) {
            setErrorMsgEmail("Payer email or cell is required");
        }
        setPayerEmail(test);
    };

    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    const classifySearch = (e) => {
        const searched = e.target && e.target.value || "";
        const searchTrimmed = searched.trim("");

        if (searchTrimmed) {
            if (rx_live.test(searched)) {
                const data = searched;
                if (searched) {
                    if (searched.length < 10) {
                        return "patientNumber";
                    } else {
                        return "phone";
                    }
                }
            } else {
                if (validator.isEmail(searched)) {
                    return "email";
                } else {
                    if (isNaN(searched)) {
                        return "name";
                    } else {
                        return "error";
                    }
                }
            }
        } else {
            return null;
        }
    };


    const onChangeHandler = (e) => {
        const searchType = classifySearch(e);

        if (searchType === "name") {
            setErrorMsgEmail("");
            setPayerName(e.target.value);
            setSendBy("payerName");
            setPayerEmail("");
            setPayerCell("");
            setPatientNumber("");
        } else if (searchType === "email") {
            setErrorMsgEmail("");
            setPayerEmail(e.target.value);
            setSendBy("email");
            setPayerName("");
            setPayerCell("");
            setPatientNumber("");
        } else if (searchType === "phone") {
            setPayerCell(e.target.value);
            setSendBy("phone");
            setErrorMsgEmail("");
            setPayerName("");
            setPayerEmail("");
            setPatientNumber("");
        } else if (searchType === "patientNumber") {
            setPatientNumber(e.target.value);
            setSendBy("patientNumber")
            setErrorMsgEmail("");
            setPayerName("");
            setPayerEmail("");
            setPayerCell("");
        } else if (searchType === "error") {
            setErrorMsgEmail(`Enter valid search info. Phone numbers should be 10 digits, without symbols.`);
            setPayerName("");
            setPayerEmail("");
            setPayerCell("");
            setPatientNumber("");
        }
        // else if (searchType === null) {
        //     setErrorMsgEmail("Search field is empty");
        // }
    };

    const handleSubmitCode = async () => {
        if (!errorMsgEmail && !errorMsgPatient) {
            const data = {
                payerEmail: payerEmail, payerCell, subdomain, payerName, patientNumber, sendBy,
            };
            if ((payerEmail && payerEmail.length > 0) || (payerCell && payerCell.length > 0)) {
                props.getPayerUserForAdminLookup(data, navigate);
                setPayerName("");
                setPayerEmail("");
                setPayerCell("");
                setPatientNumber("");
                return;
            }
            if (payerName || patientNumber) {
                //open modal for select
                await props.getPayerUserByNonuniqueIdentifier(data, setIsOpenPayerList, setPayerList, navigate, subdomain);
                setPayerName("");
                setPayerEmail("");
                setPayerCell("");
                setPatientNumber("");
                return;
            }
            setErrorMsgEmail("Update search info then submit");
            // if (patientNumber && (payerEmail || payerCell)) {

        }
    };

    const handleSubmitMessageCode = async () => {

        if (!errorMsgEmail && !errorMsgPatient) {
            const data = {
                payerEmail: payerEmail && payerEmail.toLowerCase(),
                payerCell,
                subdomain,
                payerName,
                patientNumber,
                sendBy,
            };
            if ((payerEmail && payerEmail.length > 0) || (payerCell && payerCell.length > 0)) {
                let payer = (await props.getPayerUserForAdminLookup(data, navigate, true)).data;
                if (payer && !Array.isArray(payer)) {
                    setChosenPayer(payer);
                }
                setPayerName("");
                setPayerEmail("");
                setPayerCell("");
                setPatientNumber("");
                return;
            }
            if (payerName || patientNumber) {
                //open modal for select
                let payer = await props.getPayerUserByNonuniqueIdentifier(data, setIsOpenPayerListMessage, setPayerList, navigate, subdomain, true);
                if (payer && !Array.isArray(payer)) {
                    setChosenPayer(payer);
                }
                setPayerName("");
                setPayerEmail("");
                setPayerCell("");
                setPatientNumber("");
                return;
            }

            setErrorMsgEmail("Update search info then submit");
            // if (patientNumber && (payerEmail || payerCell)) {

        }
    };

    const selectPayerUser = (selectedPayer) => {
        props.redirectPayUserForAdminLookup(selectedPayer, subdomain, navigate);
    }

    const handleDocPopUp = (event, i, type) => {
        event.preventDefault();
        setDocType(type);
        setShowPopUp(!showPopUp);
        setTarget(event.target);
        if (type === "userForm") {
            setUserFormID(i);
            setPopoverTitle("Are you sure you want to remove this submission?");
        } else if (type === "receivedmsg") {
            setMsgDataDelete(i);
            setPopoverTitle("Are you sure you want to remove this conversation?");
        } else if (type === "intakeform" || type === "document") {
            setDocId(i._id);
            setPopoverTitle("Are you sure you want to remove this form?");
        }
    };


    const updateAdminUser = () => {
        dispatch(getAdminUserData(subdomain));
    };


    const onUploadFile = (event, isDocument) => {
        const file = event.target.files[0];

        props
            .uploadMultipleFiles(file, userAdmin && userAdmin._id, isDocument, subdomain)
            .then((result) => {
                setDragDoc(false);
                setDragFile(false);
                if (isDocument === "intake") {
                    setFileUploaded(result);
                    setIsOpenInTakeSuccessful(true);
                }
            });
    };

    const handleFiles = (files, j, isDocument) => {
        for (let i = 0; i < files.length; i++) {
            if (files[i]) {
                props
                    .uploadMultipleFiles(files[i], userAdmin && userAdmin._id, isDocument, subdomain)
                    .then((result) => {
                        setDragFile(false);
                        setDragDoc(false);
                        if (isDocument === "intake") {
                            setFileUploaded(result);
                            setIsOpenInTakeSuccessful(true);
                        }
                    });
            }
        }
    };


    const onFileDrop = (e, i, isDocument) => {
        setDragFile(false);
        setDragDoc(false);
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFiles(files, i, isDocument);
    };

    const onRemoveDoc = () => {
        setShowPopUp(!showPopUp);
        const data1 = {
            payerEmail: payerEmail, payerCell, subdomain, patientNumber: patientNumber, sendBy,
        };
        if (docId) {
            const data = {
                subdomain, _id: docId, type: docType,
            };
            dispatch(deleteUploadedDoc(data));
        } else if (cardId) {
            const data = {
                cardId, userId: payerUser.id,
            };
            props.deleteCard(subdomain, data).then((res) => {
                if (res.payload) {
                    dispatch(getPayerUserForAdminLookup(data1, navigate));
                }
            });
        } else if (msgDataDelete && msgDataDelete._id) {
            const id = msgDataDelete._id;
            props.adminHardDeleteMessage(id).then((resp) => {
                if (resp) {
                    dispatch(getMessage(subdomain));
                }
            });
        } else if (userFormID) {
            props.userFormDelete(userFormID, subdomain).then((res) => {
                if (res) {
                    props.userFormDelete(userFormID, subdomain).then(() => {
                        dispatch(getUserforms(subdomain));
                    });
                }
            });
        }
    };

    const handleClosePopUp = () => {
        setShowPopUp(false);
        setDocId("");
        setCardId("");
        setMsgDataDelete("");
    };

    const onCloseModal = () => {
        setIsOpenReply(false);
        setIsLoad(false);
        setSendMsg("");

        dispatch(sendMessageSuccessNotify());
    };
    const onSendMessage = async () => {
        if (sendMsg.trim("") && messageId) {
            setSendLoader(true);
            const data = {
                msg: sendMsg, userId, messageId,
            };
            props.sendMesssageToUser(data, subdomain).then((resp) => {
                setMsgConvo(resp?.message_reponse);
                setSendMsg("");
                setSendLoader(false);
                setErrorMessage(true);
                setReplyToMessageIndex(0);
            });
        } else if (sendMsg.trim("") && !messageId && chosenPayer) {
            setSendLoader(true);
            const data = {
                msg: sendMsg, userId: chosenPayer.id
            };
            props.sendMesssageToUser(data, subdomain).then((resp) => {
                if (!isReply) {
                    setIsReply(true);
                    setChosenPayer(null);
                }
                setMsgConvo(resp?.message_reponse);
                setSendMsg("");
                setSendLoader(false);
                setErrorMessage(true);
                setReplyToMessageIndex(0);
            });
        } else {
            setErrorReplyToAdmin("Message should not be empty");
            setErrorMessage(true);
        }
    };


    const handleOnChangeMessage = (e) => {
        handleFilterById(e);
        handleRemoveId(e);
    };

    const handleClickMessage = (mesg, messageIndex) => {
        setIsReply(true);
        setReplyToMessageIndex();
        setMessageId(mesg && mesg._id);
        setUserId(mesg && mesg.userId);
        setMessageIndex(messageIndex);
        setMsgConvo(mesg || "");
        toggleReplyPopup();
    };

    const handleNewMessage = () => {
        setIsReply(false);
        setReplyToMessageIndex(null);
        setMessageId("");
        setUserId("");
        setMessageIndex(null);
        setMsgConvo({});
        toggleReplyPopup();
    }

    return (<>
        <div className="intake-content-admin-color">
            <div>
                <div className="jumbotron p-4 p-lg-5">
                    <div className="home-header-card center-content">
                        <div>


                            <b className="font-weight-lighter head-line">
                                Payer Payment Portal
                            </b>{" "}
                            Where you are able to process payments for individual accounts.
                            <div className="divider mt-2"/>
                            <FindPayer
                                submitBtnText={"Submit"}
                                isParentFullContainer={true}
                                onChangeHandler={onChangeHandler}
                                handleClick={handleClick}
                                handleKeyPress={handleKeyPress}
                                submitFunction={handleSubmitCode}
                                errorMsgEmail={errorMsgEmail}
                            />
                        </div>
                    </div>


                    <div className='flex-space-between gap-20'>
                        <div className="full-width justify-content-between row column">
                            <div className=" documents-card position-relative home-card center-message">
                                <div>
                                    <div className="card-hoiz-text d-flex justify-content-between">
                                        <div className="card-head-font  card-head-large-text flex-container-big">
                                            Messages
                                        </div>
                                        <div
                                            className="col-4"
                                        >
                                            <div className="flex-container-big-small">
                                                <div className="flex-container-big">

                                                    <div
                                                        className="flex-container-big-small sos-custom-btn-new-message sos-secondary-btn-css mb-1"
                                                        onMouseEnter={() => setNewMessageIconParentHover(true)}
                                                        onMouseLeave={() => setNewMessageIconParentHover(false)}
                                                        onClick={handleNewMessage}
                                                    >
                                                        <NewMessageIcon
                                                            color={`${newMessageIconParentHover ? "#ffffff" : "#6e86c4"}`}
                                                            height="20px"
                                                        />
                                                        <span style={{
                                                            paddingLeft: "5px",
                                                            color: `${newMessageIconParentHover ? "#ffffff" : "#6e86c4"}`,
                                                        }}

                                                        >New Message</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="header-sub mb-2 row">

                                        <div className="col-12">
                                            <div className="div-input-with-button flex-container-big-small">
                                                <input
                                                    type="text"
                                                    className="input-with-button search-id-input flex-container-big"
                                                    id="searchId"
                                                    name="searchId"
                                                    data-cy="searchId"
                                                    value={isTemp}
                                                    aria-describedby="searchId"
                                                    // placeholder="Search Name, Email, Phone, Patient #"
                                                    placeholder="Search messages by Name, Email, Phone"
                                                    onChange={(e) => {
                                                        handleOnChangeMessage(e);
                                                    }}
                                                />
                                                <button
                                                    type="submit"
                                                    // className="clear-btn"
                                                    className="button-in-input clear-btn"
                                                    data-cy="clear-btn"
                                                    onClick={(e) => handleClear(e)}
                                                >
                                                    Clear
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/*<div className="card-head-large-text card-hoiz-text d-flex justify-content-between">*/}
                                {/*    <div className="card-head-font col-4"></div>*/}
                                {/*</div>*/}

                                <div className="received-msg-inner-card upload-doc-scroll received-msg msg-margin-left"
                                     data-cy="received-msg-inner-card">
                                    {map(isSearchById, (mesg, msgIdx) => {
                                        const msgIndex = get(mesg, "messages").length - 1;
                                        const convoLastMsg = get(mesg, "messages")[msgIndex] || {};
                                        return (<React.Fragment key={msgIdx}>
                                            <div className="d-flex  justify-content-between">
                                                <div className="col-11">
                                                    <p className="admin-received-msg mb-0 align-items-center">
                                                        {convoLastMsg && convoLastMsg.sendBy === "admin" ? (
                                                            <b className="office-text">Office</b>) : (
                                                            <b>{`${mesg && mesg.name}`}</b>)}
                                                        <HorizontalArrow/>
                                                        {convoLastMsg && convoLastMsg.sendBy === "admin" ? (
                                                            <b>{`${mesg && mesg.name}`}</b>) : (
                                                            <b className="office-text">Office</b>)}
                                                    </p>
                                                    <Link
                                                        to="#"
                                                        className="message-received-text message-display-line"
                                                        onClick={() => {
                                                            handleClickMessage(mesg, msgIdx);
                                                        }}
                                                    >
                                                        {convoLastMsg?.msg && convoLastMsg.msg}
                                                    </Link>
                                                    <span className="message-received-date">
                                                        {moment(convoLastMsg.createdAt).format("MM/DD/YY")}
                                                        </span>
                                                    |
                                                    <span className="message-received-time">
                                                        {moment(convoLastMsg.createdAt).format("hh:mm A")}
                                                        </span>
                                                </div>
                                                <div
                                                    className="close-icon intakeform-download-icon col-1"
                                                    onClick={(e) => handleDocPopUp(e, mesg, "receivedmsg")}
                                                >
                                                    <CrossIcon/>
                                                </div>
                                            </div>
                                            <div className="divider"/>
                                        </React.Fragment>);
                                    })}
                                </div>
                                {/*don't delete this -- we can bring it back*/}
                                {/*<div className="document-img received-msg-img">*/}
                                {/*    <img src="/images/email-icon.png" alt="documentIcon"/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="full-width justify-content-between row column">
                            <div className="documents-card position-relative home-card center-content"
                                 data-cy="upload-doc-scroll">
                                <div className="card-head-large-text card-hoiz-text d-flex justify-content-between">
                                    <div className="card-head-font mb-1 col-10">
                                        Signed & Uploaded Patient Forms
                                    </div>
                                    <div className="col-2">
                                        <img
                                            src="/images/submitted-form-icon.png"
                                            alt="intake_icon"
                                            className="submit-icon"
                                        />
                                    </div>
                                    <div className="card-head-right-font"></div>
                                </div>

                                <div className='upload-doc-scroll received-msg'>
                                    {userForms && userForms.length > 0 ? (
                                        <div className="">
                                            <div className="">
                                                {map(userForms, (f, index) => {
                                                    console.dir('f #slkdjflksdkjf')
                                                    console.dir(f)
                                                    debugger;
                                                    return (<div key={index}>
                                                        {/*<div className="d-flex">*/}
                                                        <div>
                                                            {/* <div className="col-9 pl-0"> */}
                                                            <div className="">
                                                                <div className="item_name userid-text">
                                                                    <div className="card-inner-text-font ">
                                                                        {/* <p className=""> */}
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <a
                                                                                    className="message-received-text message-display-line"
                                                                                    target="_blank"
                                                                                    rel="noreferrer noopener"
                                                                                    onClick={() => {
                                                                                        dispatch(downloadSignedForm(
                                                                                            f.subdomain,
                                                                                            f._id,
                                                                                            `${f.name}-${moment(f.createdDate).format("MM/DD/YY-HH:mm")}-${f.originalname.replace('.docx', '.pdf').replace('.doc', '.pdf')}`,
                                                                                            "previewInNewTab"
                                                                                        ));
                                                                                    }}
                                                                                >
                                                                                    <span>{`${f.originalname}`}</span>
                                                                                </a>

                                                                            </div>
                                                                            <div className="col-3">
                                                                                {f.userId &&
                                                                                    <span>{`${f.userId?.payerLast || ""}, ${f.userId?.payerFirst || ""} ${f.userId?.payerMiddle || ""} `}</span>}
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <span>{`${moment(f.createdDate).format("MM/DD/YY")}`}</span>
                                                                            </div>
                                                                            <div className={"col-1"}>
                                                                                {f.type === "Digitally Signed" && (
                                                                                    <div className="digital-pen">
                                                                                        <CustomToolTip
                                                                                            message={"Digital e-signature form"}
                                                                                            placement={"top"}
                                                                                            children={<DigitalPen
                                                                                                color={themeColor1}/>}
                                                                                        />
                                                                                    </div>)}
                                                                            </div>
                                                                            <div className={"col-1"}>
                                                                                <div
                                                                                    className="intakeform-download-icon ">
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            if (f.template) {
                                                                                                dispatch(downloadSignedForm(
                                                                                                    f.subdomain,
                                                                                                    f._id,
                                                                                                    `${f.name}-${moment(f.createdDate).format("MM/DD/YY-HH:mm")}-${f.originalname.replace('.docx', '.pdf').replace('.doc', '.pdf')}`,
                                                                                                    "download",
                                                                                                ));
                                                                                            } else {
                                                                                                window.open(f.url, "_blank");
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <CustomToolTip
                                                                                            message={"Download"}
                                                                                            placement={"top"}
                                                                                            children={<DownArrow
                                                                                                color={themeColor1}/>}
                                                                                        />

                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                            <div className={"col-1"}>
                                                                                <div
                                                                                    className=" close-icon col-6  intakeform-download-icon mt-1"
                                                                                    onClick={(e) => handleDocPopUp(e, f._id, "userForm")}
                                                                                >
                                                                                    <CustomToolTip
                                                                                        message={"Delete"}
                                                                                        placement={"top"}
                                                                                        children={<CrossIcon/>}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="divider"/>
                                                    </div>);
                                                })}
                                            </div>
                                        </div>) : (<div></div>)}
                                </div>
                            </div>

                            {/*<ListDocumentForAdmin/>*/}
                        </div>

                    </div>

                    <CustomPopover
                        title={popoverTitle}
                        show={showPopUp}
                        target={target}
                        onRemove={onRemoveDoc}
                        close={handleClosePopUp}
                        setShowPopUp={setShowPopUp}
                    />

                    {isOpenReply && <ReplyMessagePopup
                        titleText={isReply ? "Messages" : "New Message"}
                        isReply={isReply}
                        openModal={isOpenReply}
                        onCloseModal={onCloseModal}
                        message={msgConvo}
                        userId={userId}
                        chosenPayer={chosenPayer}
                        setChosenPayer={setChosenPayer}
                        onSendSuccess={onSendMessage}
                        setSendMsg={setSendMsg}
                        sendMsg={sendMsg}
                        sendLoader={sendLoader}
                        sendMessageNotify={isSendMessageSuccess}
                        count={replyToMessageIndex}
                        errorReplyToAdmin={errorReplyToAdmin}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        setErrorReplyToAdmin={setErrorReplyToAdmin}
                        onChangeHandler={onChangeHandler}
                        handleClick={handleClick}
                        handleKeyPress={handleKeyPress}
                        submitFunction={handleSubmitMessageCode}
                        errorMsgEmail={errorMsgEmail}
                    />}


                    {isOpenPayerList && <CustomPayerListPopup
                        openModal={isOpenPayerList}
                        onCloseModal={setIsOpenPayerList}
                        selectPayerUser={selectPayerUser}
                        payerList={payerList}
                    />}

                    {isOpenPayerListMessage && <CustomPayerListPopup
                        openModal={isOpenPayerListMessage}
                        onCloseModal={setIsOpenPayerListMessage}
                        selectPayerUser={(payer) => setChosenPayer(payer)}
                        payerList={payerList}
                    />}
                </div>
            </div>
        </div>

    </>)
        ;
};
const mapDispatchToProps = {
    getPayerUserForAdminLookup,
    getPayerUserByNonuniqueIdentifier,
    redirectPayUserForAdminLookup,
    savePaymentsDataAdmin,
    uploadMultipleFiles,
    sendMesssageToUser,
    adminHardDeleteMessage,
    userFormDelete,
};
export default connect(null, mapDispatchToProps)(AdminHome);
