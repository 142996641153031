import classNames from "classnames";
import {get} from "lodash";
import React from "react";
import {Link} from "react-router-dom";
import {logoutAct, clearPayerUser} from "../../Actions/authActions";
import {
    AdminSettingIcon,
    HomeIcon,
    TransactionReportIcon,
    PeopleIcon,
    ProfileIcon,
    FormIcon,
    PersonIcon,
} from "../../Components/Icons";

import {eventUrl} from "../../utils";

const AdminHeader = (props) => {
    const {
        logoName,
        subdomain,
        userAdmin,
        dispatch,
        navigate,
        themeColor1,
        currentPage,
        setPayerEmail,
        setPayerCell,
        setPatientNumber,
    } = props;
    const prodMode = false;

    const onLogout = (e) => {
        e.preventDefault();
        dispatch(logoutAct(true, subdomain));
    };
    const onClickPageSet = () => {
        setPatientNumber("");
        setPayerEmail("");
        setPayerCell("");
        dispatch(clearPayerUser());
    };
    return (
        <>
            <div>
                <div className="w-100 justify-content-between header-card position-relative row m-0">
                    {/*<div className="logo logo-width col-lg-3 col-md-12 col-sm-12 col-xs-12">*/}
                    {/*<img*/}
                    {/*    src={logoName || "/images/logo.png"}*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="Logo"*/}
                    {/*/>*/}
                    <div className="logo short-logo-width col-lg-2 col-md-12 col-sm-12 col-xs-12">
                        <img
                            src={logoName || "/images/shortlogo.jpg"}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </div>

                    <div className="col-lg-8  intakeform-nav">
                        <ul
                            className=" nav  nav-list d-sm-flex justify-content-center"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li
                                className="home-icon nav-list-item cursor-pointer"
                                role="presentation"
                                onClick={() => {
                                    onClickPageSet("home", false);
                                    navigate(`${eventUrl(subdomain)}adminDashboard`);
                                }}
                            >
                                <a
                                    className={`nav-link header-icon text-md-center  ${
                                        currentPage === "adminDashboard" ? "nav-list-selected" : ""
                                    }`}
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                >
                                    <div className="home">
                                        <HomeIcon color={themeColor1}/>
                                    </div>
                                    <div className="text-dark d-none d-md-block">Home</div>
                                </a>
                            </li>
                            <li
                                className="nav-list-item cursor-pointer home-icon"
                                role="presentation"
                                onClick={() => {
                                    onClickPageSet("allPayers", false);
                                    navigate(
                                        `${eventUrl(subdomain)}adminDashboard/allPayers`
                                    );
                                }}
                            >
                                <a
                                    className={`nav-link header-icon text-center ${
                                        currentPage === "allPayers" ? "nav-list-selected" : ""
                                    }`}
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                >
                                    <div className="office">
                                        <PeopleIcon color={themeColor1}/>
                                    </div>
                                    <div className="text-dark d-none d-md-block">
                                        Payers
                                    </div>
                                </a>
                            </li>

                            {prodMode ? (
                                <></>
                            ) : (<>
                                    <li
                                        className="nav-list-item cursor-pointer home-icon"
                                        role="presentation"
                                        onClick={() => {
                                            onClickPageSet("Transactions", false);
                                            navigate(
                                                `${eventUrl(subdomain)}adminDashboard/transactions`
                                            );
                                        }}
                                    >
                                        <a
                                            className={`nav-link header-icon text-center ${
                                                currentPage === "transactions" ? "nav-list-selected" : ""
                                            }`}
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            <div className="office">
                                                <TransactionReportIcon color={themeColor1}/>
                                            </div>
                                            <div className="text-dark d-none d-md-block">
                                                Transactions
                                            </div>
                                        </a>
                                    </li>
                                    <li
                                        className="nav-list-item cursor-pointer home-icon"
                                        role="presentation"
                                        onClick={() => {
                                            onClickPageSet("Forms", false);
                                            navigate(
                                                `${eventUrl(subdomain)}adminDashboard/forms`
                                            );
                                        }}
                                    >
                                        <a
                                            className={`nav-link header-icon text-center ${
                                                currentPage === "forms" ? "nav-list-selected" : ""
                                            }`}
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            <div className="office">
                                                <FormIcon color={themeColor1}/>
                                            </div>
                                            <div className="text-dark d-none d-md-block">
                                                Forms
                                            </div>
                                        </a>
                                    </li>


                                    {userAdmin && userAdmin.displayStaffTab && <li
                                        className="nav-list-item cursor-pointer home-icon"
                                        role="presentation"
                                        onClick={() => {
                                            onClickPageSet("allAdmins", false);
                                            navigate(
                                                `${eventUrl(subdomain)}adminDashboard/allAdmins`
                                            );
                                        }}
                                    >
                                        <a
                                            className={`nav-link header-icon text-center ${
                                                currentPage === "allAdmins" ? "nav-list-selected" : ""
                                            }`}
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            <div className="office">
                                                <PersonIcon color={themeColor1}/>
                                            </div>
                                            <div className="text-dark d-none d-md-block">
                                                Staff
                                            </div>
                                        </a>
                                    </li>}


                                    <li
                                        className={classNames(
                                            "nav-list-item cursor-pointer"
                                        )}
                                        role="presentation"
                                        onClick={() => {
                                            onClickPageSet("office", false);
                                            navigate(`${eventUrl(subdomain)}adminDashboard/settings`);
                                        }}
                                    >
                                        <a
                                            className={`nav-link header-icon text-center ${
                                                currentPage === "settings" ? "nav-list-selected" : ""
                                            }`}
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            <div className="office">
                                                <AdminSettingIcon color={themeColor1}/>
                                            </div>
                                            <div className="text-dark d-none d-md-block">
                                                Office Settings
                                            </div>
                                        </a>
                                    </li>
                                </>
                            )}
                        </ul>

                    </div>

                    <div className="header-admin  col-lg-2 col-md-12 col-sm-12 ">
                        <div className="d-flex flex-end flex-wrap header-admin-email flex-column">
                            <span><ProfileIcon fill={'#212529'}/></span>
                            <Link
                                to="#"
                                className="text-logout message-received-text"
                                onClick={(e) => onLogout(e)}
                            >
                                Log out
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminHeader;
