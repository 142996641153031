import React, {useState, useEffect} from "react";
import {useNavigate, Link, useLocation, useParams} from "react-router-dom";
import {connect, useSelector, useDispatch} from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import filter from "lodash/filter";
import classNames from "classnames";
import PaymentSuccessPopup from "./paymentSuccessPopup";
import {withButtonLoader} from "../../helpers";
import CustomPopover from "../../Components/CustomPopover";
import CustomPopoverPayment from "../../Components/CustomPopoverPayment";

import {
    getPaymentsToken,
    getUserData,
    savePaymentsData,
    getAdminUserData,
    logoutAct,
    transactionError,
    updateUserData,
    deleteCard,
    onTransactionLoading,
    reduxSetPayerUser,
    getPayerUserById,
} from "../../Actions/authActions";
import {
    formatCreditCardNumber, formatExpirationDate, formatCVC,
} from "./utils";
import {
    eventUrl, subDomain, amountFormatter, amountFormatterWithZero,
} from "../../utils";
import CustomRadio from "../../Components/CustomRadio";
import CustomCheckbox from "../../Components/CustomCheckBox";
import EditCardInfo from "../EditCardInfo";
import "./payments.css";
import {Helmet} from "react-helmet";
import {
    CreditCardIcon, ECheckIcon, HhaCardIcon, RefreshIcon, DownArrowIcon, MousePointerIcon, AddCardIcon,

} from "../../Components/Icons";
import {PaymentFactory} from "../../Payments/paymentFactory";
import CardExpiration from "../../Components/Forms/CardExpiration";
import {showPaymentError} from "../../Payments/errorHandler";


const Payments = (props) => {
    const {match} = props || {};


    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const user = useSelector((state) => state.auth.user);
    const adminUser = useSelector((state) => state.auth.adminUser);
    const logoUrl = useSelector((state) => state.auth.adminUser?.logoUrl);
    const themeColor1 = get(adminUser, "theme.themeColor1");
    const themeColor2 = get(adminUser, "theme.themeColor2");
    let globalCustomCSSWithTheme;
    globalCustomCSSWithTheme = `.sos-mack-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}} .nav-list-item a:hover {border-bottom: 3px solid ${themeColor1 ? themeColor1 : "#6e86c4"}} .nav-list-selected {border-bottom: 3px solid ${themeColor1 ? themeColor1 : "#6e86c4"}} .message-received-text:hover {color: ${themeColor1}} .nav-item:hover {box-shadow: 0 0 0 3px ${themeColor1 ? themeColor1 : "#6e86c4"} !important;} .nav-item.active {box-shadow: 0 0 0 3px ${themeColor1 ? themeColor1 : "#6e86c4"} !important;} .custom-radio .custom-radio-checked:after {background: ${themeColor1 ? themeColor1 : "#6e86c4"}} .custom-checkbox {border: 1px solid ${themeColor1 ? themeColor1 : "#6e86c4"}} .custom-checkbox .custom-checkbox-checked:after {border: solid ${themeColor1 ? themeColor1 : "#6e86c4"}; border-width: 0 3px 3px 0;} .text-cerulean {color:${themeColor1}} .payment-raound {color:${themeColor1}} .sos-content .custom-input-border-css {border: 1px solid ${themeColor2 ? themeColor2 : "#94bbad"}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2};border: ${themeColor2} } `;
    const transactionLoading = useSelector((state) => state.auth.transactionLoading);

    const [showPopUpAutomatic, setShowPopUpAutomatic] = useState(false);
    const [title, setTitle] = useState("");
    const {patientPayer} = user || {};
    const {patientPayerInfo} = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : [];
    const [showDefaultChecked, setShowDefaultChecked] = useState();
    const [selectedCardId, setSelectedCardId] = useState("");
    // const [popoverTitle, setPopoverTitle] = useState("");
    const [cardId, setCardId] = useState("");
    const [editCardInfo, setEditCardInfo] = useState(false);
    const [isPaymentSaveDetails, setIsPaymentSaveDetails] = useState(true);
    const [showPopUp, setShowPopUp] = useState(false);
    const [target, setTarget] = useState(null);
    const [paymentType, setPaymentType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [paymentMsg, setPaymentMsg] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [selectedPayment, setSelectedPayment] = useState("");
    const [selectedAmount, setSelectedAmount] = useState("");
    const [isDefault, setIsDefault] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        name: "", amount: "", cardnumber: "", expiry: "", cvv: "",
    });
    const [accountType, setAccountType] = useState("");
    const [manual, setManual] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    const goBack = () => {
        navigate(`${eventUrl(subdomain)}home`);
    };


    useEffect(() => {
        dispatch(getAdminUserData(subdomain));
        initManualPayment();
    }, [!adminUser]);

    useEffect(() => {
        if (!(user && user.id)) {
            dispatch(getUserData(subdomain));
        }
    }, [!user]);

    useEffect(() => {
        if (user && user.paymentMethods && user.paymentMethods.length > 0) {
            const {paymentMethods} = user || "";
            const filterData = filter(paymentMethods, (p) => p.isDefault)[0];
            if (filterData && filterData._id) {
                setSelectedCardId(filterData._id);
                setSelectedPayment(filterData);
            } else {
                setSelectedCardId(paymentMethods[0]._id);
                setSelectedPayment(paymentMethods[0]);
            }
        }
    }, [user]);

    useEffect(() => {
        setIsPaymentSaveDetails(true);
    }, [paymentType]);


    useEffect(() => {
        if (user) {
            setShowDefaultChecked(user.paymentMethods);
        }
    }, [user]);

    const onLogout = () => {
        dispatch(logoutAct(false, subdomain));
    };

    const totalAmountsPaid = () => {
        const total = sumBy(map(patientPayerInfo, (i) => ({
            ...i, accountBalance: i ? Math.max(parseFloat(i.accountBalance), 0) : 0,
        })), "accountBalance");
        return total;
    };

    useEffect(() => {
        if (patientPayerInfo) {
            setSelectedAmount(totalAmountsPaid());
        }
    }, [totalAmountsPaid()]);

    const totalAmounts = () => {
        const total = sumBy(map(patientPayerInfo, (i) => ({
            ...i, accountBalance: i ? Math.max(parseFloat(i.accountBalance), 0) : 0,
        })), "accountBalance");
        return total;
    };

    const onMakePayment = () => {
        navigate(`${eventUrl(subdomain)}payments`, {});
    };
    const postManualPayment = (response) => {
        try {
            if (!response.response) {
                response.response = {...response};
            }
            const patientPayer_info = [];
            map(patientPayerInfo, (i) => {
                patientPayer_info.push({
                    patientFirst: i.patientFirst,
                    patientLast: i.patientLast,
                    patientNumber: i.patientNumber,
                    patientPayerId: i.patientPayerId,
                    amountPaid: i.accountBalance < 0 ? 0 : i.accountBalance,
                });
            });
            if (paymentType === "eCheck") {
                const transactionId = response?.ssl_txn_id || "";
                const ssl_bank_account_number = response.response?.ssl_bank_account_number?.substr(-4) || "";

                const ssl_card_short_description = response?.ssl_card_short_description || "";
                const ssl_card_type = response?.response.ssl_card_type || "";

                const paymentsDataToSave = {
                    paymentType,
                    ssl_card_short_description,
                    ssl_card_type,
                    ssl_bank_account_number,
                    userId: user && user.id,
                    transactionsData: {...response.response, bankName: response.bankName},
                    transactionId,
                    sosCustomersId: adminUser.id,
                    patientPayer: patientPayer_info,
                    isDefault,
                    isPaymentSaveDetails,
                    isAdmin: false,
                    addedBy: {userId: user.id},
                    initiatedBy: {userId: user.id},
                    subdomain,
                };
                props.savePaymentsData(paymentsDataToSave).then((res) => {
                    if (res) {
                        showResult("approval");
                    }
                });
            } else {
                const ssl_card_number = response?.ssl_card_number?.substr(-4) || "";
                const transactionId = response?.ssl_txn_id || "";
                const ssl_token = response?.ssl_token || "";

                const ssl_card_short_description = response?.ssl_card_short_description || "";
                const ssl_card_type = response?.response.ssl_card_type || "";


                const paymentsDataToSave = {
                    paymentType,
                    ssl_card_short_description,
                    ssl_card_type,
                    userId: user.id,
                    transactionsData: response,
                    transactionId,
                    ssl_card_number, // dateTimeStampUTC: new Date().toISOString(),
                    ssl_token,
                    sosCustomersId: adminUser.id,
                    patientPayer: patientPayer_info,
                    isDefault,
                    isPaymentSaveDetails,
                    isAdmin: false,
                    addedBy: {userId: user.id},
                    initiatedBy: {userId: user.id},
                    subdomain,
                };

                props.savePaymentsData(paymentsDataToSave).then((res) => {
                    if (res) {
                        localStorage.setItem('PENDING_TRANSACTION', JSON.stringify({
                            payerEmail: res.user.payerEmail,
                            patientPayer: res.user.patientPayer,
                            createdAt: response.ssl_txn_time,
                            transactionsData: response,
                            total: response.ssl_amount,
                            transactionId: transactionId,
                        }));
                        showResult("approval");
                    }
                });
            }

            setPaymentInfo({
                name: "", cardnumber: "", expiry: "", cvv: "",
            });
        } catch (err) {
            console.log("Err #3iu53urg", err.message);
            showResult("error", err);
        }
        if (user) {
            dispatch(getUserData(subdomain));
        }
    }

    const initManualPayment = () => {
        const factory = new PaymentFactory();
        const newManual = factory.create('MANUAL', {
            subdomain, adminUser, user
        });
        setManual(newManual);
    }

    function showResult(status, err) {
        if (status === "approval") {
            if (selectedAmount !== "") {
                setPaymentMsg("successful");
                setPaymentStatus("approval");
            } else {
                setPaymentMsg("addSuccessful");
                setPaymentStatus("approval");
            }
        } else if (status === "txnApprovedButPmtMethodNotStored") {
            setPaymentMsg("The payment was successful. Unable to store payment method at this time.");
            setPaymentStatus("approval");
        } else if (status === "failure" || status === "error") {
            setPaymentMsg(`${showPaymentError(err)}`);
            setPaymentStatus("error");
        }
        setOpenModal(true);
    }

    const handleInputChange = ({target}) => {
        if (target.name === "cardnumber") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        } else if (target.name === "accountType") {
            setAccountType(target.value);
        }
        setPaymentInfo({...paymentInfo, [target.name]: target.value});
    };

    const payWithSelected = async (e) => {
        dispatch(onTransactionLoading());
        const factory = new PaymentFactory();
        const patientPayer_info = [];
        map(patientPayerInfo, (i) => {
            patientPayer_info.push({
                patientFirst: i.patientFirst, patientLast: i.patientLast,
                patientNumber: i.patientNumber, patientPayerId: i.patientPayerId, amountPaid: i.accountBalance,
            });
        });
        const selected = factory.create('SELECTED', {
            amount: amountFormatterWithZero(totalAmountsPaid()),
            subdomain,
            adminUser,
            payerUser: user,
            email: get(user, "payerEmail"),
            // isPaymentSaveDetails: formdata.isPaymentSaveDetails,
            // isDefault: formData.isDefault,
            patientPayer: patientPayer_info,
            ...selectedPayment,
            isEcheck: selectedPayment.paymentType === "eCheck"
        });

        selected.pay(e).then((res) => {

            // need to clean this up because a selected payment method is already saved,
            // so isPaymentSaveDetails is more or less meaningless here.
            if (!(selected.data.isEcheck)) {
                postManualPayment(res);
            } else {
                if (res.data.message === 'Transaction successful') {
                    showResult("approval");
                    setTimeout(() => {
                        dispatch(getUserData(subdomain));
                        dispatch({type: "PAYUSER_SUCCESS"});
                        setOpenModal(false);
                        setPaymentInfo({
                            name: "", cardnumber: "", expiry: "", cvv: "",
                        });
                    }, 1000);
                } else {
                    showResult("failure", err);
                }
            }
        }).catch((err) => {
            showResult("error", err);
            dispatch(transactionError());
        });
    };

    const handleSubmit = async (e) => {
        dispatch(onTransactionLoading());
        let formData;
        if (e.target.elements) {
            formData = [...e.target.elements]
                .filter((d) => d.name)
                .reduce((acc, d) => {
                    if (["isPaymentSaveDetails", "isDefault"].includes(d.name)) {
                        acc[d.name] = d.value === "on";
                    } else {
                        acc[d.name] = d.value;
                    }
                    return acc;
                }, {});

        } else {
            formData = paymentInfo;
        }
        const payerEmail = get(user, "payerEmail") || "";
        const patientPayer_info = [];
        map(patientPayerInfo, (i) => {
            patientPayer_info.push({
                patientFirst: i.patientFirst, patientLast: i.patientLast,
                patientNumber: i.patientNumber, patientPayerId: i.patientPayerId, amountPaid: i.accountBalance,
            });
        });
        let data = {
            amount: amountFormatterWithZero(totalAmountsPaid()),
            payerUser: user,
            adminUser,
            payerEmail,
            subdomain,
            isPaymentSaveDetails,
            isDefault,
            patientPayer: patientPayer_info
        };
        if (paymentType === "eCheck") {
            const isPersonalAccount = accountType === "0";
            const isBusinessAccount = accountType === "1";
            data = {
                ...data,
                isEcheck: true,
                firstName: isPersonalAccount ? formData.accountFirstName : "",
                lastName: isPersonalAccount ? formData.accountLastName : "",
                companyName: isBusinessAccount ? formData.companyName : "",
                bankAccountType: accountType,
                bankName: formData.bankName,
                bankRoutingNumber: formData.bankRoutingNumber,
                bankAccountNumber: formData.bankAccountNumber
            }
        } else {
            data = {
                ...data,
                isEcheck: false,
                firstName: formData.cardFirstName || "",
                lastName: formData.cardLastName || "",
                expirationDate: formData && formData.expiry ? formData.expiry.replace(/\//g, "") : "",
                cardNumber: formData && formData.cardnumber,
                cvv: formData && formData.cvc ? formData.cvc : ""
            };
        }
        manual.data = data;
        manual.pay(e).then((res) => {
            if (!(data.isEcheck && data.isPaymentSaveDetails && +res.ssl_amount > 0.01)) {
                postManualPayment(res);
            } else {
                if (res.data.message === "Transaction successful") {//do we need  || res.ssl_result==="0" ?
                    showResult("approval");
                    setTimeout(() => {
                        dispatch(getUserData(subdomain));
                        dispatch({type: "PAYUSER_SUCCESS"});
                        setPaymentInfo({
                            name: "", cardnumber: "", expiry: "", cvv: "",
                        });
                        // setOpenModal(false);
                    }, 1000);
                } else {
                    showResult("failure", err);
                }
                dispatch(transactionError());

            }

        }).catch((err) => {
            console.log("Err #bbfhdb3h", err)
            showResult("error", err);
            dispatch(transactionError());
        });
    };

    const onCloseModal = () => {
        if (paymentStatus === "approval") {
            setOpenModal(false);
            navigate(`${eventUrl(subdomain)}home`);
        } else {
            setOpenModal(false);
        }
    };

    const handlePopUp = (event, i) => {
        setShowPopUp(!showPopUp);
        setTarget(event.target);
        // setPopoverTitle("Are you sure you want to remove this payment method?");
        setCardId(i._id);
    };

    const handleClosePopUp = () => {
        setShowPopUp(false);
        setCardId("");
    };

    const handleEditInfo = () => {
        setEditCardInfo(!editCardInfo);
        setSelectedPayment(selectedPayment);
        const noBalancePatientPayerInfo = patientPayerInfo.map(o => {
            return {
                ...o, accountBalance: "0"
            }
        });
        localStorage.setItem('state', JSON.stringify({noBalancePatientPayerInfo}));
        setSelectedAmount('');
    };

    const onDefaultChecked = (e, c, i) => {
        let userChecked = [...user.paymentMethods];
        let updateDefaultCard = [];
        const updatedUser = user;
        const selectedPayerId = e.target.checked ? userChecked[i]._id : "";


        map(userChecked, (p) => {
            const defaultCard = p;
            if (p._id === c._id) {
                defaultCard.isDefault = e.target.checked;
            } else {
                defaultCard.isDefault = false;
            }
            updateDefaultCard.push(defaultCard);
        });
        setShowDefaultChecked(updateDefaultCard);
        updatedUser.paymentMethods = updateDefaultCard;
        dispatch(updateUserData(updatedUser));
    };


    const onRemoveDoc = () => {
        setShowPopUp(!showPopUp);
        if (cardId) {
            const data = {
                cardId, userId: user.id,
            };
            props.deleteCard(subdomain, data).then((res) => {
                if (res.payload) {
                    dispatch(getUserData(subdomain));
                }
            });
        }
    };

    return (<>
        <div className="wrapper">
            <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>
            <div className="sos-card-login home-patient-box">
                <div className="w-100 d-flex justify-content-between">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                    {/*<div >*/}
                        <div className="logo">
                            <img
                                src={logoUrl || "/images/logo.png"}
                                className="img-fluid"
                                alt="Logo"
                            />
                        </div>
                        <div>
                            <div style={{marginLeft:"20px"}}>
                                <span>{adminUser && adminUser.officeName && adminUser.officeName.toUpperCase() || ''}</span>
                            </div>
                            <div style={{marginLeft:"20px"}}>
                                <span>{adminUser && adminUser.publicEmail || ''}</span>
                            </div>
                            <div style={{marginLeft:"20px"}}>
                                <span>{adminUser && adminUser.publicPhone || ''}</span>
                            </div>
                        </div>
                    </div>
                    <div className="header">
                        <div className="d-flex align-items-center">
                            <span>{get(user, "payerEmail")}</span>
                            <Link
                                to="#"
                                className="text-cerulean message-received-text"
                                onClick={() => onLogout()}
                            >
                                Logout
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="d-md-flex sos-mack">
                    <div className="navbar-content">
                        <nav className="bg-light">
                            <ul className="navbar-nav ">
                                <li className="nav-item home-link">
                                    <Link
                                        className="nav-link"
                                        to={`${eventUrl(subdomain)}home`}
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    {/*<div onClick={()=>onMakePayment()} className="nav-link active">*/}
                                    {/*    Make a Payment*/}
                                    {/*</div>*/}
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="payment-patient">
                        <div className="payment-patient-inner position-relative">
                            {!editCardInfo ? (<div>
                                <div
                                    className="hommeexisting-large-text hommeexisting-hoiz-text d-flex  justify-content-between">
                                    <p>{selectedAmount > "" ? `Amount Being Paid` : ''} </p>
                                    <p>{selectedAmount > "" ? 'Account Balance' : ''}</p>
                                </div>
                                <div
                                    className="cross-btn"
                                    onClick={() => goBack()}
                                >
                                    <svg width="15px" height="17px" viewBox="0 0 18 18">
                                        <path
                                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                    </svg>
                                </div>
                                <div className="payment-inner-text container">
                                    <div className="payment-amount d-flex  justify-content-between">
                                        <p className="ml-5">
                                            {selectedAmount > "" ? `$${amountFormatterWithZero(selectedAmount)}` : ''}
                                        </p>
                                        <span className="ml-5 account-balance">
                          {selectedAmount > "" ? `${amountFormatterWithZero(totalAmounts())}` : ''}
                        </span>
                                    </div>
                                    {paymentType === "creditCard" || paymentType === "eCheck" || paymentType === "hhaCard" ? (
                                        <div>
                                            <div className="payment-patient-style">
                                                {selectedAmount === "" ? 'Add payment method' : 'Select your payment method'}
                                            </div>
                                            <div className="container payment-toggleable">
                                                <ul
                                                    className="justify-content-lg-center justify-content-center mb-3 nav nav-pills"
                                                    id="pills-tab"
                                                    role="tablist"
                                                >
                                                    {adminUser.displayECheck && (<li
                                                        className={classNames("nav-item chekbox-custom mr-2 me-2 rectangle", paymentType === "eCheck" ? "active" : "")}
                                                        role="presentation"
                                                        onClick={() => setPaymentType("eCheck")}
                                                    >
                                                        <a
                                                            className="nav-link text-center"
                                                            id="pills-home-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-home"
                                                            role="tab"
                                                            aria-controls="pills-home"
                                                            aria-selected="true"
                                                        >
                                                            <CustomRadio
                                                                defaultChecked={paymentType === "eCheck"}
                                                                checked={paymentType === "eCheck"}
                                                            />
                                                            <div className="check-btn">
                                                                <ECheckIcon/>
                                                            </div>
                                                            <div className="payment-checkbox-inner">
                                                                eCheck
                                                            </div>
                                                        </a>
                                                    </li>)}
                                                    <li
                                                        className={classNames("nav-item chekbox-custom mr-2 me-2 rectangle", paymentType === "creditCard" ? "active" : "")}
                                                        role="presentation"
                                                        onClick={() => setPaymentType("creditCard")}
                                                    >
                                                        <a
                                                            className="nav-link text-center"
                                                            id="pills-profile-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-profile"
                                                            role="tab"
                                                            aria-controls="pills-profile"
                                                            aria-selected="false"
                                                        >
                                                            <CustomRadio
                                                                defaultChecked={paymentType === "creditCard"}
                                                                checked={paymentType === "creditCard"}
                                                            />
                                                            <div className="check-btn">
                                                                <CreditCardIcon/>
                                                            </div>
                                                            <div className="payment-checkbox-inner">
                                                                Credit/Debit
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li
                                                        className={classNames("nav-item chekbox-custom me-2 rectangle", paymentType === "hhaCard" ? "active" : "")}
                                                        role="presentation"
                                                        onClick={() => setPaymentType("hhaCard")}
                                                    >
                                                        <a
                                                            className="nav-link text-center"
                                                            id="pills-contact-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-contact"
                                                            role="tab"
                                                            aria-controls="pills-contact"
                                                            aria-selected="false"
                                                        >
                                                            <CustomRadio
                                                                defaultChecked={paymentType === "hhaCard"}
                                                                checked={paymentType === "hhaCard"}
                                                            />
                                                            <div className="check-btn">
                                                                <HhaCardIcon/>
                                                            </div>
                                                            <div className="payment-checkbox-inner">
                                                                HHA/HHS card
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div
                                                        className={classNames("tab-pane", paymentType === "eCheck" ? "fade show active" : "")}
                                                        id="pills-home"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-home-tab"
                                                    >
                                                        <div className="sos-content payment-inner-toggleable">
                                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                                <div className="dropdown custom-dropdown mb-3">
                                                                    <select
                                                                        name="accountType"
                                                                        className="form-control custom-input-border-css"
                                                                        required
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    >
                                                                        <option value="">Account Type</option>
                                                                        <option value="0">Personal</option>
                                                                        <option value="1">Business</option>
                                                                    </select>
                                                                    <div className="down-arrow">
                                                                        <DownArrowIcon/>
                                                                    </div>
                                                                </div>

                                                                {accountType === "0" ? (
                                                                    <div className="form-group mb-3">
                                                                        <label
                                                                            htmlFor="exampleInputBankName1"
                                                                            className="form-label payment-form-label"
                                                                        >
                                                                            Name On Account
                                                                        </label>
                                                                        <div className="row g-2">
                                                                            <div className="col-md">
                                                                                <div
                                                                                    className="payment-controlpanl-style">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="accountFirstName"
                                                                                        className="form-control custom-input-border-css"
                                                                                        id="exampleInputName3"
                                                                                        aria-describedby="nameHelp"
                                                                                        placeholder="First Name"
                                                                                        onChange={(e) => handleInputChange(e)}
                                                                                        required
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md">
                                                                                <div>
                                                                                    <input
                                                                                        type="text"
                                                                                        name="accountLastName"
                                                                                        className="form-control custom-input-border-css"
                                                                                        id="exampleInputName3"
                                                                                        aria-describedby="nameHelp"
                                                                                        placeholder="Last Name"
                                                                                        onChange={(e) => handleInputChange(e)}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>) : (<div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputBankName1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Name On Account
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="companyName"
                                                                        required
                                                                        className="form-control custom-input-border-css"
                                                                        id="exampleInputName1"
                                                                        aria-describedby="nameHelp"
                                                                        placeholder="Enter Name on the Account"
                                                                    />

                                                                </div>)}
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputBankName1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Bank Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        name="bankName"
                                                                        required
                                                                        className="form-control custom-input-border-css"
                                                                        id="exampleInputBankName"
                                                                        aria-describedby="bankNameHelp"
                                                                        placeholder="Enter Bank Name"
                                                                    />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputBankNumber1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Bank Routing Number
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        required
                                                                        className="form-control custom-input-border-css"
                                                                        name="bankRoutingNumber"
                                                                        id="exampleInputBankNumber1"
                                                                        placeholder="Enter Bank Routing Number"
                                                                    />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputAccountNumber1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Bank Account Number
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control custom-input-border-css"
                                                                        id="exampleInputAccountNumber1"
                                                                        required
                                                                        name="bankAccountNumber"
                                                                        placeholder="Enter Bank Account Number"
                                                                    />
                                                                </div>

                                                                <div className="checkbox-list mt-3">
                                                                    <label
                                                                        className={`label-check font-weight-light float-end ${
                                                                            selectedAmount === "" ? "disabled-label" : ""
                                                                        }`}>
                                                                        <CustomCheckbox
                                                                            name="isPaymentSaveDetails"
                                                                            checked={selectedAmount === "" ? true : isPaymentSaveDetails}
                                                                            onChange={(e) => setIsPaymentSaveDetails(e.target.checked)}
                                                                        />
                                                                        Save these payment details for future
                                                                        payments
                                                                    </label>
                                                                    <div className="payment-checbox">
                                                                        <label
                                                                            className="font-weight-light label-check ml-xl-5">
                                                                            <CustomCheckbox
                                                                                name="isDefault"
                                                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                                            />
                                                                            Set as default payment method
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="sos-mack-btn sos-custom-btn-css"
                                                                        disabled={transactionLoading}
                                                                    >

                                                                        {withButtonLoader(`${
                                                                            selectedAmount === ""
                                                                                ? "Add Payment Method"
                                                                                : "Make a Payment"
                                                                        }`, transactionLoading)}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={classNames("tab-pane", paymentType === "creditCard" ? "fade show active" : "")}
                                                        id="pills-profile"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-profile-tab"
                                                    >
                                                        <div className="sos-content payment-inner-toggleable">
                                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputEmail1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Cardholder Name
                                                                    </label>
                                                                    <div className="row g-2">
                                                                        <div className="col-md">
                                                                            <div
                                                                                className="payment-controlpanl-style">
                                                                                <input
                                                                                    type="text"
                                                                                    name="cardFirstName"
                                                                                    className="form-control custom-input-border-css"
                                                                                    id="exampleInputName3"
                                                                                    aria-describedby="nameHelp"
                                                                                    placeholder="First Name"
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                    required
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md">
                                                                            <div>
                                                                                <input
                                                                                    type="text"
                                                                                    name="cardLastName"
                                                                                    className="form-control custom-input-border-css"
                                                                                    id="exampleInputName3"
                                                                                    aria-describedby="nameHelp"
                                                                                    placeholder="Last Name"
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputPassword1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Credit Card Number
                                                                    </label>
                                                                    <input
                                                                        type="tel"
                                                                        name="cardnumber"
                                                                        className="form-control custom-input-border-css"
                                                                        id="cardnumber"
                                                                        placeholder="Enter Credit Card Number"
                                                                        pattern="[\d| ]{15,22}"
                                                                        required
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                                <div className="row g-2">
                                                                    <div className="col-md">
                                                                        <div
                                                                            className="payment-controlpanl-style">
                                                                            <div>
                                                                                <label
                                                                                    className="payment-form-label">
                                                                                    Expiry Date
                                                                                </label>
                                                                            </div>
                                                                            <CardExpiration
                                                                                onChange={(e) => handleInputChange(e)}
                                                                            ></CardExpiration>
                                                                            {/*<input*/}
                                                                            {/*    type="tel"*/}
                                                                            {/*    className="form-control custom-input-border-css"*/}
                                                                            {/*    id="creidtExpiryDate"*/}
                                                                            {/*    placeholder="mm/yy"*/}
                                                                            {/*    name="expiry"*/}
                                                                            {/*    pattern="\d\d/\d\d"*/}
                                                                            {/*    required*/}
                                                                            {/*    onChange={(e) => handleInputChange(e)}*/}
                                                                            {/*/>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md">
                                                                        <div>
                                                                            <div>
                                                                                <label
                                                                                    className="payment-form-label">
                                                                                    Security Code
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                type="tel"
                                                                                className="form-control custom-input-border-css"
                                                                                placeholder="Enter CVC"
                                                                                name="cvc"
                                                                                pattern="\d{3,4}"
                                                                                required
                                                                                onChange={(e) => handleInputChange(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="checkbox-list mt-3">
                                                                    <label
                                                                        className={`label-check font-weight-light ${
                                                                            selectedAmount === "" ? "disabled-label" : ""
                                                                        }`}>
                                                                        <CustomCheckbox
                                                                            name="isPaymentSaveDetails"
                                                                            disabled={selectedAmount === ""}
                                                                            checked={selectedAmount === "" ? true : isPaymentSaveDetails}
                                                                            onChange={(e) => setIsPaymentSaveDetails(e.target.checked)}
                                                                        />
                                                                        <span>
                                          Save these payment details for future
                                          payments
                                        </span>
                                                                    </label>
                                                                    <div className="payment-checbox">
                                                                        <label
                                                                            className="font-weight-light label-check ml-xl-5">
                                                                            <CustomCheckbox
                                                                                name="isDefault"
                                                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                                            />
                                                                            <span
                                                                                className="word-break: break-word">
                                            Set as default payment method
                                          </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="sos-mack-btn sos-custom-btn-css"
                                                                        disabled={transactionLoading}
                                                                    >
                                                                        {withButtonLoader(`${
                                                                            selectedAmount === ""
                                                                                ? "Add Payment Method"
                                                                                : "Make a Payment"
                                                                        }`, transactionLoading)}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={classNames("tab-pane fade", paymentType === "debitCard" ? "show active" : "")}
                                                        id="pills-contact"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-contact-tab"
                                                    >
                                                        <div className="sos-content payment-inner-toggleable">
                                                            <form>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputEmail1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Cardholder Name
                                                                    </label>
                                                                    <div className="row g-2">
                                                                        <div className="col-md">
                                                                            <div
                                                                                className="payment-controlpanl-style">
                                                                                <input
                                                                                    type="text"
                                                                                    name="cardFirstName"
                                                                                    className="form-control custom-input-border-css"
                                                                                    id="exampleInputName3"
                                                                                    aria-describedby="nameHelp"
                                                                                    placeholder="First Name"
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                    required
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md">
                                                                            <div>
                                                                                <input
                                                                                    type="text"
                                                                                    name="cardLastName"
                                                                                    className="form-control custom-input-border-css"
                                                                                    id="exampleInputName3"
                                                                                    aria-describedby="nameHelp"
                                                                                    placeholder="Last Name"
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputPassword1"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Debit Card Number
                                                                    </label>
                                                                    <input
                                                                        type="tel"
                                                                        className="form-control custom-input-border-css"
                                                                        id="debitcardNumber"
                                                                        placeholder="Enter Debit Card Number"
                                                                    />
                                                                </div>
                                                                <div className="row g-2">
                                                                    <div className="col-md">
                                                                        <div
                                                                            className="payment-controlpanl-style">
                                                                            <div>
                                                                                <label
                                                                                    className="payment-form-label">
                                                                                    Expiry Date
                                                                                </label>
                                                                            </div>
                                                                            <CardExpiration
                                                                                onChange={(e) => handleInputChange(e)}
                                                                            ></CardExpiration>
                                                                            {/*<input*/}
                                                                            {/*    type="date"*/}
                                                                            {/*    className="form-control custom-input-border-css"*/}
                                                                            {/*    id="debitExpiryDate"*/}
                                                                            {/*    placeholder="mm/yy"*/}
                                                                            {/*/>*/}

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md">
                                                                        <div>
                                                                            <div>
                                                                                <label
                                                                                    className="payment-form-label">
                                                                                    Security Code
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                type="tel"
                                                                                className="form-control custom-input-border-css"
                                                                                id="floatingInputGrid"
                                                                                placeholder="Enter CVC"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="checkbox-list mt-3">
                                                                    <label
                                                                        className={`label-check font-weight-light float-end ${
                                                                            selectedAmount === "" ? "disabled-label" : ""
                                                                        }`}>
                                                                        <CustomCheckbox
                                                                            name="isPaymentSaveDetails"
                                                                            disabled={selectedAmount === ""}
                                                                            checked={selectedAmount === "" ? true : isPaymentSaveDetails}
                                                                            onChange={(e) => setIsPaymentSaveDetails(e.target.checked)}
                                                                        />
                                                                        Save these payment details for future
                                                                        payments
                                                                    </label>
                                                                    <div className="payment-checbox">
                                                                        <label
                                                                            className="font-weight-light label-check ml-xl-5">
                                                                            <CustomCheckbox
                                                                                name="isDefault"
                                                                                checked={isDefault}
                                                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                                            />
                                                                            Set as default payment method
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="sos-mack-btn sos-custom-btn-css"
                                                                        disabled={transactionLoading}
                                                                    >
                                                                        {withButtonLoader(`${
                                                                            selectedAmount === ""
                                                                                ? "Add Payment Method"
                                                                                : "Make a Payment"
                                                                        }`, transactionLoading)}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`tab-pane fade ${paymentType === "hhaCard" ? "show active" : ""}`}
                                                        id="pills-contact"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-contact-tab"
                                                    >
                                                        <div className="sos-content payment-inner-toggleable">
                                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputName4"
                                                                        className="form-label payment-form-label"
                                                                    >
                                                                        Cardholder Name
                                                                    </label>
                                                                    <div className="row g-2">
                                                                        <div className="col-md">
                                                                            <div
                                                                                className="payment-controlpanl-style">
                                                                                <input
                                                                                    type="text"
                                                                                    name="cardFirstName"
                                                                                    className="form-control custom-input-border-css"
                                                                                    id="exampleInputName3"
                                                                                    aria-describedby="nameHelp"
                                                                                    placeholder="First Name"
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                    required
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md">
                                                                            <div>
                                                                                <input
                                                                                    type="text"
                                                                                    name="cardLastName"
                                                                                    className="form-control custom-input-border-css"
                                                                                    id="exampleInputName3"
                                                                                    aria-describedby="nameHelp"
                                                                                    placeholder="Last Name"
                                                                                    onChange={(e) => handleInputChange(e)}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <label
                                                                        htmlFor="exampleInputPassword1"
                                                                        className="form-label payment-form-label "
                                                                    >
                                                                        HHA/HHS Card Number
                                                                    </label>
                                                                    <input
                                                                        type="tel"
                                                                        name="cardnumber"
                                                                        className="form-control custom-input-border-css"
                                                                        placeholder="Enter HHA/HHS Card Number"
                                                                        pattern="[\d| ]{15,22}"
                                                                        required
                                                                        onChange={(e) => handleInputChange(e)}
                                                                    />
                                                                </div>
                                                                <div className="row g-2">
                                                                    <div className="col-md">
                                                                        <div
                                                                            className="payment-controlpanl-style">
                                                                            <div>
                                                                                <label
                                                                                    className="payment-form-label">
                                                                                    Expiry Date
                                                                                </label>
                                                                            </div>
                                                                            <CardExpiration
                                                                                onChange={(e) => handleInputChange(e)}
                                                                            ></CardExpiration>
                                                                            {/*<input*/}
                                                                            {/*    type="tel"*/}
                                                                            {/*    className="form-control custom-input-border-css"*/}
                                                                            {/*    id="hhaExpiryDate"*/}
                                                                            {/*    placeholder="mm/yy"*/}
                                                                            {/*    name="expiry"*/}
                                                                            {/*    pattern="\d\d/\d\d"*/}
                                                                            {/*    required*/}
                                                                            {/*    onChange={(e) => handleInputChange(e)}*/}
                                                                            {/*/>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md">
                                                                        <div>
                                                                            <div>
                                                                                <label
                                                                                    className="payment-form-label">
                                                                                    Security Code
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                type="tel"
                                                                                className="form-control custom-input-border-css"
                                                                                placeholder="Enter CVC"
                                                                                name="cvc"
                                                                                pattern="\d{3,4}"
                                                                                required
                                                                                onChange={(e) => handleInputChange(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="checkbox-list mt-3">
                                                                    <label
                                                                        className={`label-check font-weight-light float-end ${
                                                                            selectedAmount === "" ? "disabled-label" : ""
                                                                        }`}>
                                                                        <CustomCheckbox
                                                                            name="isPaymentSaveDetails"
                                                                            disabled={selectedAmount === ""}
                                                                            checked={selectedAmount === "" ? true : isPaymentSaveDetails}
                                                                            onChange={(e) => setIsPaymentSaveDetails(e.target.checked)}
                                                                        />
                                                                        Save these payment details for future
                                                                        payments
                                                                    </label>
                                                                    <div className="payment-checbox">
                                                                        <label
                                                                            className="font-weight-light label-check ml-xl-5 ">
                                                                            <CustomCheckbox
                                                                                name="isDefault"
                                                                                checked={isDefault}
                                                                                onChange={(e) => setIsDefault(e.target.checked)}
                                                                            />
                                                                            Set as default payment method
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="sos-mack-btn sos-custom-btn-css"
                                                                        disabled={transactionLoading}
                                                                    >
                                                                        {withButtonLoader("Make a Payment", transactionLoading)}
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>) : (<>

                                        {user?.paymentMethods?.length > 0 ? (<React.Fragment>
                                            <div className="pay-saved-method">
                                                <div className="pay-patient-style">
                                                    {user?.paymentMethods?.length > 0 ? "Saved Payment Methods" : ""}
                                                </div>

                                                <div className="pay-toggleable d-flex row ">

                                                    {map(user.paymentMethods, (c, i) => {
                                                        let paymentMethodDisplay;
                                                        if (c.ssl_card_short_description) {
                                                            paymentMethodDisplay = `${c.ssl_card_short_description} **** ${c.ssl_card_number.slice(-4)}`;
                                                        } else {
                                                            paymentMethodDisplay = `eCheck ** ${c.ssl_bank_account_number.slice(-4)}`;
                                                        }

                                                        return (<ul
                                                            key={i}
                                                            className="mb-3 nav nav-pills col-lg-4 col-xl-4 col-md-6 justify-content-center  cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedCardId(c._id);
                                                                setSelectedPayment(c);
                                                            }}
                                                        >
                                                            <li
                                                                className={("nav-item custom-tab border-1 ml-8", selectedCardId === c._id ? " nav-item active select-card" : " nav-item custom-tab")}
                                                                role="presentation"
                                                            >
                                                                <a
                                                                    className="nav-link text-center "
                                                                    id="pills-home-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#pills-home"
                                                                    role="tab"
                                                                    aria-controls="pills-home"
                                                                    aria-selected="true"
                                                                >
                                                                    <div
                                                                        className="check-btn d-flex mb-0">
                                                                        {c.ssl_card_short_description === "eCheck" ? (
                                                                            <ECheckIcon/>) : (
                                                                            <CreditCardIcon/>)}
                                                                        <span
                                                                            className="text-dark payment-card-text">{paymentMethodDisplay}
                                              </span>
                                                                    </div>
                                                                    <div
                                                                        className="mb-0 ml-8 text-start text-dark mt-1">
                                                                        <label
                                                                            className="font-weight-light label-check sos-checkbox">
                                                                            <CustomCheckbox
                                                                                checked={c.isDefault}
                                                                                onChange={(e) => onDefaultChecked(e, c, i)}
                                                                            />
                                                                            <span
                                                                                className="payment-style payment-method-text">
                                                  Default Method
                                                </span>
                                                                        </label>
                                                                    </div>
                                                                    <div
                                                                        className="check-btn d-flex auto-payment text-dark">

                                                                    </div>
                                                                    <div className="divider"/>
                                                                    <div className="d-flex">
                                              <span
                                                  className="text-cerulean text-decoration-underline ml-8"
                                                  onClick={() => handleEditInfo()}
                                              >
                                                Edit
                                              </span>
                                                                        <span
                                                                            className="text-cerulean text-decoration-underline"
                                                                            onClick={(e) => handlePopUp(e, c)}
                                                                        >
                                                Remove
                                              </span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>);
                                                    })}
                                                </div>
                                                {selectedAmount > "" && <div className="d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        onClick={(e) => payWithSelected(e)}
                                                        className="sos-mack-btn sos-custom-btn-css"
                                                        disabled={transactionLoading}
                                                    >
                                                        {withButtonLoader("Process Payment", transactionLoading)}
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="pay-saved-inner-block">
                                                <div className="or-line"/>
                                                {selectedAmount > "" ?
                                                    (<div className="payment-raound">
                                                        <span>OR</span>
                                                    </div>) : (<div className="payment-raound-secondary">
                                                        <span></span>
                                                    </div>)
                                                }
                                            </div>
                                        </React.Fragment>) : ("")}

                                        {(selectedAmount > "" || adminUser.displayAddPaymentMethod) && (<>
                                            <div className="pay-patient-style select-payment">
                                                {selectedAmount > '' ? `Pay With a New Payment Method` : 'Add Payment Method'}
                                            </div>
                                            <div className="container pay-toggleable">
                                                <ul
                                                    className=" justify-content-around mb-3 nav nav-pills"
                                                    id="pills-tab"
                                                    role="tablist"
                                                >
                                                    {adminUser.displayECheck && (<li
                                                        // me-5
                                                        className="nav-item chekbox-custom mr-2 rectangle"
                                                        role="presentation"
                                                        onClick={() => setPaymentType("eCheck")}
                                                    >
                                                        <a
                                                            className={classNames("nav-link text-center", paymentType === "eCheck" ? "active" : "")}
                                                            id="pills-home-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-home"
                                                            role="tab"
                                                            aria-controls="pills-home"
                                                            aria-selected="true"
                                                        >
                                                            <div className="check-btn">
                                                                <ECheckIcon/>
                                                            </div>
                                                            <div className="payment-checkbox-inner">
                                                                eCheck
                                                            </div>
                                                        </a>
                                                    </li>)}
                                                    <li
                                                        className="nav-item chekbox-custom mr-2 rectangle"
                                                        role="presentation"
                                                        onClick={() => setPaymentType("creditCard")}
                                                    >
                                                        <a
                                                            className={classNames("nav-link text-center", paymentType === "creditCard" ? "active" : "")}
                                                            id="pills-profile-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-profile"
                                                            role="tab"
                                                            aria-controls="pills-profile"
                                                            aria-selected="false"
                                                        >
                                                            <div className="check-btn">
                                                                <CreditCardIcon/>
                                                            </div>
                                                            <div className="payment-checkbox-inner">
                                                                Credit/Debit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="nav-item chekbox-custom rectangle"
                                                        role="presentation"
                                                        onClick={() => setPaymentType("hhaCard")}
                                                    >
                                                        <a
                                                            className={classNames("nav-link text-center", paymentType === "hhaCard" ? "active" : "")}
                                                            id="pills-contact-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-contact"
                                                            role="tab"
                                                            aria-controls="pills-contact"
                                                            aria-selected="false"
                                                        >
                                                            <div className="check-btn">
                                                                <HhaCardIcon/>
                                                            </div>
                                                            <div className="payment-checkbox-inner">
                                                                HHA/HHS card
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>)}
                                    </>)}
                                </div>
                            </div>) : (<EditCardInfo
                                patientPayerInfo={patientPayerInfo}
                                paymentType={paymentType}
                                subdomain={subdomain}
                                paymentMethodData={selectedPayment}
                                setEditCardInfo={setEditCardInfo}
                            />)}
                            {editCardInfo ? (<></>) : (<div></div>
                                // <div className="hommeexisting-img">
                                //   <img src="../images/payment-icon.png" />
                                // </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <CustomPopover
                title={"Are you sure you want to remove this payment method?"}
                show={showPopUp}
                target={target}
                onRemove={onRemoveDoc}
                close={handleClosePopUp}
                setShowPopUp={setShowPopUp}
            />
            <PaymentSuccessPopup
                openModal={openModal}
                paymentMsg={paymentMsg}
                onCloseModal={onCloseModal}
                officeName={adminUser.officeName}
            />
        </div>
    </>);
};
const mapDispatchToProps = {
    savePaymentsData, deleteCard,
};
export default connect(null, mapDispatchToProps)(Payments);
