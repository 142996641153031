import map from "lodash/map";
import {DownArrow, HistoryIcon, SignOnline, TrashCanIcon} from "../../Components/Icons";
import {withButtonLoader} from "../../helpers";
import CustomPopoverHelloSign from "../../Components/CustomPopoverHelloSign";
import CustomePopUp from "../../Components/customePopUp";
import React, {useRef, useState, useEffect} from "react";
import HistoryIconPopup from "./historyPopup";
import get from "lodash/get";
import HelloSignUser from "../../Components/HelloSignUser";
import moment from "moment/moment";
import {executeGet} from "../../Api";
import {GET_EMBEDDED_SIGN_ERROR} from "../../constants";
import {addToUserforms} from "../../Actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import {AppropriateFormTypeIcon, formInstructionsPerType} from "../../Components/AppropriateFormType";
import {SignDocument} from "../../Refactor/SignDocument/Sign/sign-document";

const UserForms = ({subdomain, themeColor1, adminUser, uploadUserForms, user}) => {
    const dispatch = useDispatch();

    let intakeFormUserData = adminUser?.intakeforms;
    let userForms = useSelector((state) => state.auth.userForms);

    // userForms.sort((a, b) => {
    //     if (a.formType > b.formType) return 1;
    //     if (a.formType < b.formType) return -1;
    //     return 0;
    // })

    const intakeForm = useRef(null);
    const [signIntakeForm, setSignIntakeForm] = useState(null);
    const [historyPopup, setHistoryPopup] = useState(false);
    const [historyPopupData, setHistoryPopupData] = useState("");
    const [helloSign, setHelloSign] = useState(null);
    const [showHelloSign, setShowHelloSign] = useState(false);
    const [dragFile, setDragFile] = useState(false);
    const [intakePopUp, setIntakePopUp] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [hellosignTitle, setHellosignTitle] = useState("");
    const [showCPopUp, setShowCPopUp] = useState(false);
    const uploadFileLoading = useSelector((state) => state.auth.uploadUserFormLoading);
    const embeddedHelloSignUrl = useSelector((state) => state.auth.embeddedHelloSignUrl);
    const [target, setTarget] = useState(null);

    const [isLoadingUploading, setIsLoadingUploading] = useState(false);
    const [signTemplateId, setSignTemplateId] = useState(null);
    const [showAllClinicForms, setShowAllClinicForms] = useState(true);

    const userHasInvitedForms = user && user.invitedForms && user.invitedForms.length > 0;
    useEffect(() => {
        if (userHasInvitedForms) {
            setShowAllClinicForms(false);
        }
    }, [intakeFormUserData, user]);

    intakeFormUserData = intakeFormUserData.filter(o => showAllClinicForms ? true : user.invitedForms.includes(o._id.toString()));
    // intakeFormUserData.sort((a, b) => {
    //     if (a.formType > b.formType) return 1;
    //     if (a.formType < b.formType) return -1;
    //     return 0;
    // })

    const toggleShowAllClinicForms = () => {
        setShowAllClinicForms(!showAllClinicForms);
    }

    const customUi = get(adminUser, "customUi");
    const intakePopUpClose = () => {
        setIntakePopUp(false);
        setPopUpMessage("");
    };
    const onClickBrowse = (e) => {
        e.preventDefault();

        intakeForm.current.click();
    };
    const handleHistoryIcon = (e, temp) => {
        setHistoryPopup(!historyPopup);
        setHistoryPopupData(temp);
    };

    const validateFile = (file) => {
        const fileName = get(file, "name");
        const re = /(\.pdf|\.doc|\.docx)$/i;
        if (re.exec(fileName)) {
            return true;
        }
        return false;
    };

    const handleCloseCPopUp = () => {
        setShowCPopUp(false);
    };


    const onUploadIntakeForm = (e) => {
        const documentType = "upload";
        e.preventDefault();
        const files = e.target.files[0];
        props
            .uploadUserForms(files, user.id, documentType, subdomain)
            .then(() => {
                setIntakePopUp(true);
                setPopUpMessage("Form sent successfully.");
            });
    };

    const signatureRequestInit = async (e, intakeform) => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        e.preventDefault();
        setSignIntakeForm(intakeform);
        const intakeformid = intakeform._id.toString();
        const userid = user && user.id;
        const signs = userForms.length > 0 && userForms.filter((f) => f.userId && f.intakeformId === intakeformid && f.userId.toString() === userid);
        if (signs && signs.length > 0) {
            let str = "";
            await signs.forEach((element) => {
                str = `${moment(element.createdDate).fromNow()}`;
            });
            setHellosignTitle(`You signed this form ${str}, would you like to sign it again?`);
            setTarget(e.target);
            setShowCPopUp(true);
            setIsLoading(false);
        } else {
            signatureRequest(e, intakeform);
        }
    };

    const signatureRequest = async (e, intakeform) => {
        e.preventDefault();
        const payerEmail = user.payerEmail || "";
        const payerName = "Form Signer";
        const template_id = (await intakeform) && intakeform.template && intakeform.template.template_id;
        // dispatch(createSignatureRequestAndGetUrl(subdomain, template_id, payerEmail, payerName));
        executeGet(`hellosign/createSignatureRequest/${subdomain}/${template_id}/${payerEmail}/${payerName}`)
            .then((res) => {
                if (res) {
                    setSignTemplateId(template_id);
                    const embeddedHelloSignUrl = res && res.data;
                    setHelloSign(embeddedHelloSignUrl);
                    setShowHelloSign(!showHelloSign);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log("err woejgjei", err);
                dispatch({type: GET_EMBEDDED_SIGN_ERROR});
                return err;
            });
    };

    const onSignHellosign = (signatureId) => {

        const {originalname, url, mimetype, size} = signIntakeForm;
        const intakeformId = signIntakeForm._id.toString();
        const templateId = signIntakeForm.template.template_id;
        if (templateId !== signTemplateId) {
            return;
        }
        if (isLoadingUploading) {
            return;
        }
        setIsLoadingUploading(true);
        const userId = user.id;
        const sosCustomerId = adminUser._id;
        const signature_request_id = helloSign.signature_request_id;
        const data = {
            originalname,
            url,
            mimetype,
            size,
            signatureId,
            intakeformId,
            subdomain,
            templateId,
            signature_request_id,
            userId,
            sosCustomerId,
            type: "Digitally Signed",
        };
        dispatch(addToUserforms({userFormData: data}, subdomain, intakeformId));
        setTimeout(() => {
            setIsLoadingUploading(false);
            setSignTemplateId(null);
        }, 2000);
        // setSignIntakeForm(null)
        //setShowHelloSign(!showHelloSign);
    };

    const onCloseModal = () => {
        setHistoryPopup(false);
    };

    const intakeFormDrop = (e) => {
        e.preventDefault();
        setDragFile(false);
        const files = e.dataTransfer.files[0];
        handleFile(files);
    };

    const dragOver = (e) => {
        e.preventDefault();
        setDragFile(true);
    };
    const dragLeave = (e) => {
        e.preventDefault();
        setDragFile(false);
    };

    const handleFile = (files) => {
        const documentType = "upload";
        uploadUserForms(files, user.id, documentType, subdomain)
            .then(() => {
                setIntakePopUp(true);
                setPopUpMessage("Form was uploaded successfully and was sent to the clinic.");
            });
    };


    return <div className="container">

        <div className="mt-3 full-width sos-content soscontent-mobileview soscontent-background" data-cy="doc-upload">
            <div className="bg-white position-relative m-b-20 center-content account-card-shadow p-3 mt-2">
                <div
                    className="position-relative account-balance-card m-auto mt-1 mb-5">


                    {userHasInvitedForms ? (<div style={{display: "flex"}}>
                        <div className="hommeexisting-large-text hommeexisting-hoiz-text">
                            {showAllClinicForms ? "All Forms & Documents" : "Forms That Require Your Attention"}
                        </div>
                        {/*<div style={{display:"inline-block", fontSize:"24px", marginRight:"1em"}}>View:</div>*/}
                        {/*onClick={() => setRSelected(1)}*/}
                        <div
                            style={{display: "inline-block"}}
                            // className={`sos-custom-btn-new-message ${showAllClinicForms ? "sos-custom-btn-css" : "sos-secondary-btn-css"} mb-1 ps-3 pe-3 float-right-inside-flex`}
                            className={`sos-custom-btn-new-message sos-secondary-btn-css mb-1 ps-3 pe-3 float-right-inside-flex`}
                            onClick={() => toggleShowAllClinicForms()}
                        >

                            {showAllClinicForms ? "Show Your Forms Only" : "Show All Clinic Forms"}
                        </div>
                    </div>) : (<div className="hommeexisting-large-text hommeexisting-hoiz-text">
                        All Clinic Forms & Documents
                    </div>)}


                    {intakeFormUserData && intakeFormUserData.length > 0 ? (
                        <div className="inner-card-form inner-card-document-form">
                            <div className="upload-intake-scroll h-500 doc-intake upload-doc-scroll">

                                {map(intakeFormUserData, (i, index) => {
                                    console.dir('intakeFormUserData')
                                    console.dir(intakeFormUserData)

                                    const isFormTypeTransition = index === 0 || (index < intakeFormUserData.length && i?.formType !== intakeFormUserData[index - 1]?.formType);
                                    return (<div
                                        key={index}
                                    >
                                        {isFormTypeTransition && <div
                                            className='mt-4 mb-2'
                                        >
                                            <div
                                            >{formInstructionsPerType.find(o => o.formType === i.formType).longPatientInstructions}</div>
                                        </div>}
                                        <div key={index}
                                             className="flex flex-align-center space-between border-bottom pe-3 ps-1 py-1">

                                            <div>
                                                <h5 className="card-inner-text-font">
                                                    {i && i.originalname}
                                                </h5>
                                            </div>

                                            <div className={'flex flex-gap-10 flex-align-center'}>
                                                <div className={'flex'}>
                                                    {/*<div className="d-flex justify-content-between">*/}
                                                    {i?.slug && i.formType === 'eSign' && (<>
                                                        <div
                                                            onClick={(e) => {
                                                                setSignIntakeForm(i);
                                                                signatureRequestInit(e, i, index);
                                                                setShowCPopUp(true);
                                                            }}
                                                            className="intakeform-download-icon text-below-icons">
                                                            <div>
                                                                <SignOnline
                                                                    width="24px"
                                                                    color="themeColor1"/>
                                                            </div>
                                                            <div>
                                                                Sign
                                                            </div>
                                                        </div>
                                                    </>)}
                                                </div>
                                                {/*<div*/}
                                                {/*    className="intakeform-download-icon text-below-icons flex"*/}
                                                {/*    onClick={(e) => handleHistoryIcon(e, i, index)}>*/}
                                                {/*    <div>*/}
                                                {/*        <HistoryIcon*/}
                                                {/*            width="24px"*/}
                                                {/*            color={themeColor1}*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*    <div>*/}
                                                {/*        History*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div
                                                    className="intakeform-download-icon cursor-pointer text-below-icons flex">

                                                    <a
                                                        className={'flex'}
                                                        href={i && i.url} download
                                                    >
                                                        <div
                                                            className="text-center cursor-pointer"
                                                        >
                                                            {/*<TrashCanIcon color={themeColor1} height="1.4vw"/>*/}
                                                            <DownArrow color={themeColor1} height="1.4vw"/>
                                                            <div className={'link'}>Download</div>
                                                        </div>

                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>) : (<div className="when-you-have-no-intake">
                        When the office shares informational documents, they will
                        appear in this area.
                    </div>)}

                    <div className="card-head-font">
                        {`${customUi && customUi.addADocumentOrIntakeForm || "Upload Signed Forms Here (to send them to the clinic)"}`}
                    </div>
                    <div
                        className="hommeexisting-inner-drag-drop"
                        onDragOver={(e) => dragOver(e)}
                        onDrop={(a) => intakeFormDrop(a)}
                        onDragLeave={(e) => dragLeave(e)}
                    >

                        <div className="homeexisting-drag-and-drop-comple dropzone-loader">
                            {uploadFileLoading ? (withButtonLoader("", uploadFileLoading, "#6e86c4")) : (<>
                                {dragFile ? (withButtonLoader("", dragFile, "#6e86c4")) : (
                                    <div className="drag-and-drop-comple ">
                                        <>
                                            {/*<p>*/}
                                            {/*Upload completed forms here*/}
                                            {/*<br/>*/}
                                            (Click button or Drag & Drop to upload)
                                            {/*</p>*/}

                                            <div className=" justify-content-center">
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="logoUpload"
                                                    style={{display: "none"}}
                                                    onChange={(e) => onUploadIntakeForm(e)}
                                                    ref={intakeForm}
                                                />

                                                <button
                                                    data-cy="browse-files-btn"
                                                    type="submit"
                                                    className="sos-browse-btn sos-custom-btn-css"
                                                    onClick={(e) => onClickBrowse(e)}
                                                >
                                                    Click to upload your file
                                                </button>
                                            </div>
                                        </>
                                    </div>)}
                            </>)}

                            {signIntakeForm && showCPopUp &&
                                <SignDocument doc={signIntakeForm}
                                              close={handleCloseCPopUp}
                                              subdomain={subdomain}
                                              sosCustomerId={adminUser._id}
                                              userId={user.id}
                                              userEmail={user.payerEmail}
                                />}
                            <CustomePopUp
                                openModal={intakePopUp}
                                message={popUpMessage}
                                onCloseModal={intakePopUpClose}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <HistoryIconPopup
            openModal={historyPopup}
            onCloseModal={onCloseModal}
            userForms={userForms}
            historyPopupData={historyPopupData}
        />
        {showHelloSign && helloSign && helloSign.embeddedHelloSignUrl && (<HelloSignUser
            embeddedHelloSignUrl={helloSign.embeddedHelloSignUrl}
            showHelloSign={showHelloSign}
            setShowHelloSign={setShowHelloSign}
            payerEmail={user.payerEmail}
            onSignHellosign={onSignHellosign}
        />)}
    </div>
}
export default UserForms;
