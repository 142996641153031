import React, {useEffect, useState} from "react";
import {Modal, Overlay, Popover} from "react-bootstrap";
import "./popover.css";
import {CrossIcon} from "../Icons";
import map from "lodash/map";
import {amountFormatterWithZero} from "../../utils";
import sumBy from "lodash/sumBy";
import {withButtonLoader} from "../../helpers";

const AbstractCustomPopover = ({
                                   title = "",
                                   subtext,
                                   show,
                                   target,
                                   close,
                                   isLoading,
                                   themeColor1,
                                   actionButtonText,
                                   callAction,
                                   itemForPopupDisplay,
                                   HtmlTableDisplayItem,
                               }) => {

    useEffect(() => {
        // if (transaction.patientPayer) {
        //     setPatientPayerInfo(transaction.patientPayer.map(o => {
        //         return {...o, amountRefunded: o.amountPaid};
        //     }));
        // }

    }, [itemForPopupDisplay]);

    const handleClick = (e) => {
        e.target.select();
    };

    const doAction = () => {
        callAction(itemForPopupDisplay);
    };


    return (<>
        <div>
            <Modal show={show} size="md" centered className="update-successfully">
                <div data-cy="modal-pop-up" onClick={() => close()} className="close-modal-btn">
                    <CrossIcon/>
                </div>
                <Modal.Body>
                    <div className="modal-text">
                        <h2 className="modal-title">{title}</h2>
                        <p className="modal-title">{subtext}</p>
                        <div className="mb-3"></div>

                        <HtmlTableDisplayItem
                            itemForPopupDisplay={itemForPopupDisplay}
                        />
                        <div className="flex flex-align-center flex-end">
                            <button
                                className="custom-popover-button  sos-custom-btn-css"
                                onClick={close}
                            >
                                Cancel
                            </button>

                            <button
                                className="custom-popover-button close-btn sos-custom-btn-css m-l-10"
                                onClick={() => doAction()}
                            >
                                {withButtonLoader(actionButtonText, isLoading === true, themeColor1)}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    </>);
};

export default AbstractCustomPopover;
