import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get, map } from "lodash";
import {
  adminSentAccessCode,
  getLogo,
  sendCodeByTextMsg,
  verificationAccessCode,
} from "../../Actions/authActions";
import { getParameterByName } from "../../helpers";
import { subDomain, isNumberKey } from "../../utils";
import Toast from "../../Components/Toast";
import "./adminregistration.css";
import { Helmet } from "react-helmet";

const AdminRegistration = (props) => {
  const { match } = props || {};
  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const userToken = params.userToken || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const adminUser = useSelector((state) => state.auth.adminUser);
  const cname = getParameterByName("cname") || "";
  const cemail = getParameterByName("cemail");
  const logoUrl = localStorage.getItem("logoUrl");
  const theme = useSelector((state) => state.auth.theme);
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");

  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
  const { createUser, tokenHash, isAdmin, createAdminNotOwnerPassword } =
      localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : false;


  const [adminPhone, setAdminPhone] = useState("");
  const [code, setCode] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [registerLinkErr, setRegisterLinkErr] = useState(false);
  const [registerLinkErrMsg, setRegisterLinkErrMsg] = useState("");
  const [step, setStep] = useState(0);
  const [editableData, setEditableData] = useState({
    adminFirst: cname.split(" ")[0],
    adminLast: cname.split(" ")[1],
    adminEmail: cemail,
  });
  const agreedToS = {
    agreedToS: false,
    err: false,
    errText: "Please accept Terms of Service and Privacy Policy to continue.",
  };

  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  const [fieldsList, setFieldsList] = useState([
    {
      key: "adminEmail",
      type: "email",
      title: "Enter email",
      placeholder: "Enter email",
      err: false,
      errText: "",
      className: "col-12",
      value: "",
    },
    {
      key: "password",
      type: "password",
      placeholder: "Password",
      title: "Create password",
      err: false,
      errText: "",
      className: "col-md-6 col-12",
      value: "",
    },
    {
      key: "rePass",
      type: "password",
      placeholder: "Re-enter password",
      title: "",
      err: false,
      errText: "",
      className: "col-md-6 col-12",
      value: "",
    },
  ]);

  const handleFields = (e, i, key) => {
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    if (len < 37) {
      if (key === "adminEmail") {
        obj[key] = value.toLowerCase();
      } else {
        obj[key] = value;
      }
    }
    if (arr[i].err) {
      arr[i].err = false;
      arr[i].errText = "";
    }
    if (key === "adminEmail") {
      arr[i].value = value.toLowerCase();
    } else {
      arr[i].value = value;
    }
    setEditableData(obj);
    setFieldsList(arr);
  };

  const isValid = () => {
    const arr = [...fieldsList];
    const { adminEmail, password, rePass } = editableData || {};
    // const agreedToSValue = agreedToS.agreedToS || false;
    const handleErr = (i, msg) => {
      arr[i].err = true;
      arr[i].errText = msg;
    };
    if (!createUser) {
      // if(agreedToSValue === false){
      //   //set error string
      //   agreeToSCheckError(true);
      //   return false;
      // }

      if (!adminEmail) {
        handleErr(0, "Email is required.");
      }
    }
    if (!password) {
      handleErr(1, "Password is required.");
    } else if (password.length < 12) {
      handleErr(1, "Password must be at least 12 characters.");
    } else if (!rePass) {
      handleErr(2, "Re-enter password.");
    } else if (rePass !== password) {
      handleErr(2, "Passwords do not match.");
    }
    setFieldsList(arr);
    if (
      (!createUser && !adminEmail) ||
      !password ||
      !rePass ||
      rePass !== password ||
      password.length < 12
    ) {
      return false;
    }
    return true;
  };

  const onVerifyCode = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { password } = editableData || {};
    const data = {
      ...user,
      accessCode: code,
      createPass: true,
      password,
    };
    if (code) {
      dispatch(verificationAccessCode(data, navigate, true)); //david removed isAdmin from this
    }
  };

  const onSendCode = () => {
    const { adminFirst, adminLast, adminEmail, password } = editableData || {};
    const { agreedToSValue } = agreedToS || false;
    const data = {
      adminFirst: adminFirst || "",
      adminLast: adminLast || "",
      street: "",
      city: "",
      state: "",
      zip: "",
      adminPhone: adminPhone,
      adminEmail: adminEmail,
      password: password,
      agreeToS: agreedToSValue || "unknown",
      tokenHash: "",
      createPass: true,
      subdomain,
    };
    if (adminPhone === "") {
      setIsPhoneError(true);
      setPhoneErrorMsg("Cell number is required.");
    } else if (adminPhone.length < 10) {
      setIsPhoneError(true);
      setPhoneErrorMsg("Cell number must be at least 10 digits.");
    } else {
      setIsPhoneError(false);
      setPhoneErrorMsg("");
      sendCodeByTextMsg(data);
      setStep(2);
    }
  };

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setAdminPhone(value);
    setIsPhoneError(false);
    setPhoneErrorMsg("");
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (!tokenHash) {
      setRegisterLinkErr(true);
      setRegisterLinkErrMsg(
        "Please use the admin registration link sent to your email."
      );
      return;
    }

    if (isValid()) {
      const { adminFirst, adminLast, adminEmail, password } = editableData || {};
      const { agreedToSValue } = agreedToS || false;
      const data = {
        adminFirst: adminFirst,
        adminLast: adminLast || "",
        street: "",
        city: "",
        state: "",
        zip: "",
        adminPhone: "",
        adminEmail: adminEmail,
        password: password,
        agreeToS: agreedToSValue || "unknown",
        tokenHash: tokenHash,
        createPass: true,
        isAdmin: isAdmin || false,
        subdomain,
        sendBy: "email",
        id: adminUser._id,
        createAdminNotOwnerPassword,
      };

      dispatch(adminSentAccessCode(data, navigate, isAdmin));

      // if (!createUser) {
      //   dispatch(adminSentAccessCode(data, navigate, isAdmin));
      // } else {
      //   setStep(1);
      // }
    }
  };

  const handleKeyPress = (e, key) => {
    if (e.key === "Enter") {
      if (key === 0) {
        handleRegister(e);
      }
    }
  };

  return (
    <>
      <div className="wrapper">
        <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
        <div className="sos-card-login sos-card">
          <div className="logo w-100 d-flex justify-content-center">
            <img
              src={logoUrl ? logoUrl : "/images/logo.png"}
              className="img-fluid"
              alt="Logo"
            />
          </div>
          {userToken && (
            <div className="text-cerulean">
              Welcome! Please create your password for your account
            </div>
          )}
          {step === 0 && (
            <div className="sos-content">
              <>
                <form>
                  {map(fieldsList, (i, index) => {
                    return (
                      <div className="form-group mb-2" key={index}>
                        {i && i.title && (
                          <label
                            htmlFor={`label_${i.title}`}
                            className="form-label"
                          >
                            {i.title}
                          </label>
                        )}
                        <input
                          type={i.type}
                          value={i.value}
                          className="form-control"
                          id={`label_${i.title}`}
                          aria-describedby="emailHelp"
                          placeholder={i.placeholder}
                          onChange={(e) => handleFields(e, index, i.key)}
                          onKeyPress={(e) => handleKeyPress(e, 0)}
                        />
                        {i && i.err && (
                          <Toast
                            isOpen={i.err}
                            showClose={false}
                            msg={i.errText}
                            type={"danger"}
                            dur={3000}
                          />
                        )}
                      </div>
                    );
                  })}
                </form>
                <Toast
                  isOpen={registerLinkErr}
                  showClose={false}
                  msg={registerLinkErrMsg}
                  type={"danger"}
                  dur={15000}
                  closeCallbackFn={() => {
                    setRegisterLinkErr(false);
                    setRegisterLinkErrMsg("");
                  }}
                />
                <div className="d-flex justify-content-end save-btn mt-4">
                  <button
                    type="button"
                    onClick={(e) => handleRegister(e)}
                    className="sos-custom-btn sos-custom-btn-css"
                  >
                    Save
                  </button>
                </div>
              </>
            </div>
          )}
          {step === 1 && (
            <div className="sos-content">
              <a href="#" className="text-cerulean d-block mb-4">
                Your account was set up with the office. Enter your phone number
                to receive a text message with a code.
              </a>
              <>
                <div className="form-group">
                  <label htmlFor="exampleInputText" className="form-label">
                    Get a code texted to you to log in
                  </label>
                  <input
                    type="text"
                    name="adminPhone"
                    className="form-control"
                    placeholder="Enter Your Cell Number"
                    onChange={(e) => onChangeHandler(e)}
                    maxLength={10}
                    onKeyPress={(e) => isNumberKey(e)}
                  />
                  <Toast
                    isOpen={isPhoneError}
                    showClose={false}
                    msg={phoneErrorMsg}
                    type={"danger"}
                    dur={3000}
                  />
                </div>
                <div>
                  <a
                    href="#"
                    className="text-cerulean d-block mb-2 Standard-text"
                  >
                    Standard data fees and text messaging fees may apply.
                  </a>
                </div>
                <div className="d-flex justify-content-end getcode-btn">
                  <button
                    type="button"
                    onClick={() => onSendCode()}
                    className="sos-custom-btn sos-custom-btn-css"
                  >
                    Get Code
                  </button>
                </div>
              </>
            </div>
          )}
          {step === 2 && (
            <div className="sos-content">
              <div>
                <a
                  href="#"
                  className="text-cerulean d-block mb-4 text-cerulean-position"
                >
                  You should have received a code texted to your phone. Please
                  enter that code into the field below.
                </a>
                <>
                  <div className="form-group">
                    <label htmlFor="exampleInputText" className="form-label">
                      Enter the code you received
                    </label>
                    <input
                      type="text"
                      name="code"
                      className="form-control"
                      placeholder="Enter Your Code"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                </>
              </div>
              <div className="d-flex justify-content-end submit-btn">
                <button
                  type="button"
                  className="sos-custom-btn sos-custom-btn-css"
                  onClick={() => onVerifyCode()}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminRegistration;
