import classnames from "classnames";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./toolTip.css";

const CustomToolTip = ({ flag = false, message, children, placement }) => {

  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip
          id={`tooltip-${placement}`}
          className={classnames(flag ? "send-icon" : "customeToolTip")}
        >
          {message}
        </Tooltip>
      }
    >
        <span aria-describedby={`tooltip-${placement}`}>{children}</span>
    </OverlayTrigger>
  );
};

export default CustomToolTip;
