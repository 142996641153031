export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET_ERROR_STATE = "LOGIN_RESET_ERROR_STATE";

export const ACCOUNT_LOGOUT = "ACCOUNT_LOGOUT";

export const FILE_UPLOAD_LOADING = "FILE_UPLOAD_LOADING";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";

export const TASK_SAVE_LOADING = "TASK_SAVE_LOADING";
export const TASK_SAVE_SUCCESS = "TASK_SAVE_SUCCESS";
export const TASK_SAVE_ERROR = "TASK_SAVE_ERROR";

export const TASK_DATA_LOADING = "TASK_DATA_LOADING";
export const TASK_DATA_SUCCESS = "TASK_DATA_SUCCESS";
export const TASK_DATA_ERROR = "TASK_DATA_ERROR";

export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const SETTING_LOADING = "SETTING_LOADING";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_ERROR = "SETTING_ERROR";


export const FETCH_CURRENT_USER_LOADING = "FETCH_CURRENT_USER_LOADING";
export const FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS";
export const FETCH_CURRENT_USER_ERROR = "FETCH_CURRENT_USER_ERROR";

export const FETCH_GETALL_DATA_LOADING = "FETCH_GETALL_DATA_LOADING";
export const FETCH_GETALL_DATA_SUCCESS = "FETCH_GETALL_DATA_SUCCESS";
export const FETCH_GETALL_DATA_ERROR = "FETCH_GETALL_DATA_ERROR";

export const FETCH_GETALLADMINS_LOADING = "FETCH_GETALLADMINS_LOADING";
export const FETCH_GETALLADMINS_SUCCESS = "FETCH_GETALLADMINS_SUCCESS";
export const FETCH_GETALLADMINS_ERROR = "FETCH_GETALLADMINS_ERROR";

export const FETCH_USER_ACCOUNTS_LOADING = "FETCH_USER_ACCOUNTS_LOADING";
export const FETCH_USER_ACCOUNTS_SUCCESS = "FETCH_USER_ACCOUNTS_SUCCESS";
export const FETCH_USER_ACCOUNTS_ERROR = "FETCH_USER_ACCOUNTS_ERROR";

export const FETCH_ADMIN_USER_LOADING = "FETCH_ADMIN_USER_LOADING";
export const FETCH_ADMIN_USER_SUCCESS = "FETCH_ADMIN_USER_SUCCESS";
export const FETCH_ADMIN_USER_ERROR = "FETCH_ADMIN_USER_ERROR";

export const FORGOT_EMAIL_ERROR = "FORGOT_EMAIL_ERROR";
export const FORGOT_EMAIL_SUCCESS = "FORGOT_EMAIL_SUCCESS";
export const FORGOT_EMAIL_LOADING = "FORGOT_EMAIL_LOADING";

export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";


export const CHECK_VALIDHASH_ERROR = "CHECK_VALIDHASH_ERROR";
export const CHECK_VALIDHASH_SUCCESS = "CHECK_VALIDHASH_SUCCESS";
export const CHECK_VALIDHASH_LOADING = "CHECK_VALIDHASH_LOADING";

export const UPDATE_EMAIL_LOADING = "UPDATE_EMAIL_LOADING";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_ERROR = "UPDATE_EMAIL_ERROR";

export const CHECK_VALID_EMAIL_LOADING = "CHECK_VALID_EMAIL_LOADING";
export const CHECK_VALID_EMAIL_SUCCESS = "CHECK_VALID_EMAIL_SUCCESS";
export const CHECK_VALID_EMAIL_ERROR = "CHECK_VALID_EMAIL_ERROR";

export const GENERATE_ACCESS_CODE_ERROR = "GENERATE_ACCESS_CODE_ERROR";
export const GENERATE_ACCESS_CODE_SUCCESS = "GENERATE_ACCESS_CODE_SUCCESS";
export const GENERATE_ACCESS_CODE_LOADING = "GENERATE_ACCESS_CODE_LOADING";

export const VERIFY_ACCESS_CODE_ERROR = "VERIFY_ACCESS_CODE_ERROR";
export const VERIFY_ACCESS_CODE_SUCCESS = "VERIFY_ACCESS_CODE_SUCCESS";
export const VERIFY_ACCESS_CODE_LOADING = "VERIFY_ACCESS_CODE_LOADING";

export const FETCH_TRANSACTION_DATA_LOADING = "FETCH_TRANSACTION_DATA_LOADING";
export const FETCH_TRANSACTION_DATA_SUCCESS = "FETCH_TRANSACTION_DATA_SUCCESS";
export const FETCH_TRANSACTION_DATA_ERROR = "FETCH_TRANSACTION_DATA_ERROR";

export const DELETE_USERDATA_LOADING = "DELETE_USERDATA_LOADING";
export const DELETE_USERDATA_SUCCESS = "DELETE_USERDATA_SUCCESS";
export const DELETE_USERDATA_ERROR = "DELETE_USERDATA_ERROR";
export const DELETE_USERDATA_INITIAL = "DELETE_USERDATA_INITIAL";

export const ADD_ADMIN_LOADING = "ADD_ADMIN_LOADING";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR";

export const ADMIN_LOGIN_INVITE_LOADING = "ADMIN_LOGIN_INVITE_LOADING";
export const ADMIN_LOGIN_INVITE_SUCCESS = "ADMIN_LOGIN_INVITE_SUCCESS";
export const ADMIN_LOGIN_INVITE_ERROR = "ADMIN_LOGIN_INVITE_ERROR";

export const LOGIN_ACCESSCODE_SUCCESS = "LOGIN_ACCESSCODE_SUCCESS";
export const LOGIN_ACCESSCODE_LOADING = "LOGIN_ACCESSCODE_LOADING";
export const LOGIN_ACCESSCODE_ERROR = "LOGIN_ACCESSCODE_ERROR";

export const VERIFY_LOGIN_ACCESSCODE_ERROR = "VERIFY_LOGIN_ACCESSCODE_ERROR";
export const VERIFY_LOGIN_ACCESSCODE_SUCCESS =
  "VERIFY_LOGIN_ACCESSCODE_SUCCESS";
export const VERIFY_LOGIN_ACCESSCODE_LOADING =
  "VERIFY_LOGIN_ACCESSCODE_LOADING";

export const UPDATEPASSWORD_LINK_SUCCESS = "UPDATEPASSWORD_LINK_SUCCESS";
export const UPDATEPASSWORD_LINK_SUCCESS_RESET = "UPDATEPASSWORD_LINK_SUCCESS_RESET";
export const UPDATEPASSWORD_LINK_LOADING = "UPDATEPASSWORD_LINK_LOADING";
export const UPDATEPASSWORD_LINK_ERROR = "UPDATEPASSWORD_LINK_ERROR";
export const UPDATEPASSWORD_LINK_ERROR_RESET = "UPDATEPASSWORD_LINK_ERROR_RESET";

export const ADMIN_UPDATE_PASSWORD_LOADING = "ADMIN_UPDATE_PASSWORD_LOADING";
export const ADMIN_UPDATE_PASSWORD_SUCCESS = "ADMIN_UPDATE_PASSWORD_SUCCESS";
export const ADMIN_UPDATE_PASSWORD_ERROR = "ADMIN_UPDATE_PASSWORD_ERROR";

export const ADMIN_CHECK_VALIDHASH_LOADING = "ADMIN_CHECK_VALIDHASH_LOADING";
export const ADMIN_CHECK_VALIDHASH_SUCCESS = "ADMIN_CHECK_VALIDHASH_SUCCESS";
export const ADMIN_CHECK_VALIDHASH_ERROR = "ADMIN_CHECK_VALIDHASH_ERROR";

export const ADMIN_PASSWORD_SUCCESS = "ADMIN_PASSWORD_SUCCESS";
export const ADMIN_PASSWORD_ERROR = "ADMIN_PASSWORD_ERROR";
export const ADMIN_PASSWORD_LOADING = "ADMIN_PASSWORD_LOADING";

export const ADMIN_LOGIN_ACCESSCODE_LOADING = "ADMIN_LOGIN_ACCESSCODE_LOADING";
export const ADMIN_LOGIN_ACCESSCODE_SUCCESS = "ADMIN_LOGIN_ACCESSCODE_SUCCESS";
export const ADMIN_LOGIN_ACCESSCODE_ERROR = "ADMIN_LOGIN_ACCESSCODE_ERROR";

export const FETCH_CURRENT_ADMIN_LOADING = "FETCH_CURRENT_ADMIN_LOADING";
export const FETCH_CURRENT_ADMIN_SUCCESS = "FETCH_CURRENT_ADMIN_SUCCESS";
export const FETCH_CURRENT_ADMIN_ERROR = "FETCH_CURRENT_ADMIN_ERROR";

export const UPLOAD_FILE_LOADING = "UPLOAD_FILE_LOADING";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";

export const PAYUSER_LOADING = "PAYUSER_LOADING";
export const PAYUSER_SUCCESS = "PAYUSER_SUCCESS";
export const PAYUSER_ERROR = "PAYUSER_ERROR";
export const PAYUSER_CLEAR = "PAYUSER_CLEAR";

export const DELETE_UPLOADED_LOADING = "DELETE_UPLOADED_LOADING";
export const DELETE_UPLOADED_SUCCESS = "DELETE_UPLOADED_SUCCESS";
export const DELETE_UPLOADED_ERROR = "DELETE_UPLOADED_ERROR";

export const UPDATE_ADMIN_DATA_LOADING = "UPDATE_ADMIN_DATA_LOADING";
export const UPDATE_ADMIN_DATA_SUCCESS = "UPDATE_ADMIN_DATA_SUCCESS";
export const UPDATE_ADMIN_DATA_ERROR = "UPDATE_ADMIN_DATA_ERROR";

export const SEND_SMS_LOADING = "SEND_SMS_LOADING";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_ERROR = "SEND_SMS_ERROR";

export const UPLOAD_LOGO_FILE_LOADING = "UPLOAD_LOGO_FILE_LOADING";
export const UPLOAD_LOGO_FILE_SUCCESS = "UPLOAD_LOGO_FILE_SUCCESS";
export const UPLOAD_LOGO_FILE_ERROR = "UPLOAD_LOGO_FILE_ERROR";

export const SAVE_TRANSACTION_LOADING = "SAVE_TRANSACTION_LOADING";
export const SAVE_TRANSACTION_SUCCESS = "SAVE_TRANSACTION_SUCCESS";
export const SAVE_TRANSACTION_ERROR = "SAVE_TRANSACTION_ERROR";

export const DELETE_CARD_LOADING = "DELETE_CARD_LOADING";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_ERROR = "DELETE_CARD_ERROR";

export const OFFICE_CONTACT_LOADING = "OFFICE_CONTACT_LOADING";
export const OFFICE_CONTACT_SUCCESS = "OFFICE_CONTACT_SUCCESS";
export const OFFICE_CONTACT_ERROR = "OFFICE_CONTACT_ERROR";

export const USER_CONTACT_LOADING = "USER_CONTACT_LOADING";
export const USER_CONTACT_SUCCESS = "USER_CONTACT_SUCCESS";
export const USER_CONTACT_ERROR = "USER_CONTACT_ERROR";

export const UPDATE_USER_DATA_LOADING = "UPDATE_USER_DATA_LOADING";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_ERROR = "UPDATE_USER_DATA_ERROR";

export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_LOADING = "DOWNLOAD_FILE_LOADING";

export const RESENDCODE_SUCCESS = "RESENDCODE_SUCCESS";
export const RESENDCODE_LOADING = "RESENDCODE_LOADING";
export const RESENDCODE_ERROR = "RESENDCODE_ERROR";

export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_ERROR = "LOGIN_ADMIN_ERROR";
export const LOGIN_ADMIN_LOADING = "LOGIN_ADMIN_LOADING";

export const USER_SENDCODE_SUCCESS = "USER_SENDCODE_SUCCESS";
export const USER_SENDCODE_LOADING = "USER_SENDCODE_LOADING";
export const USER_SENDCODE_ERROR = "USER_SENDCODE_ERROR";

export const GET_EMBEDDED_SIGN_SUCCESS = "GET_EMBEDDED_SIGN_SUCCESS";
export const GET_EMBEDDED_SIGN_LOADING = "GET_EMBEDDED_SIGN_LOADING";
export const GET_EMBEDDED_SIGN_ERROR = "GET_EMBEDDED_SIGN_ERROR";

export const GET_MESSAGE_LOADING = "GET_MESSAGE_LOADING";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_ERROR = "GET_MESSAGE_ERROR";

export const SAVE_HELLOSIGN_TEMPLATE_SUCCESS =
  "SAVE_HELLOSIGN_TEMPLATE_SUCCESS";
export const SAVE_HELLOSIGN_TEMPLATE_LOADING =
  "SAVE_HELLOSIGN_TEMPLATE_LOADING";
export const SAVE_HELLOSIGN_TEMPLATE_ERROR = "SAVE_HELLOSIGN_TEMPLATE_ERROR";

export const GET_HELLOSIGN_TEMPLATE_SUCCESS = "SAVE_HELLOSIGN_TEMPLATE_SUCCESS";
export const GET_HELLOSIGN_TEMPLATE_LOADING = "SAVE_HELLOSIGN_TEMPLATE_LOADING";
export const GET_HELLOSIGN_TEMPLATE_ERROR = "SAVE_HELLOSIGN_TEMPLATE_ERROR";

export const DELETE_MESSAGE_USER_SUCCESS = "DELETE_MESSAGE_USER_SUCCESS";
export const DELETE_MESSAGE_USER_ERROR = "DELETE_MESSAGE_USER_ERROR";
export const DELETE_MESSAGE_USER_LOADING = "DELETE_MESSAGE_USER_LOADING";

export const SHOW_USER_MESSAGE_SUCCESS = "SHOW_USER_MESSAGE_SUCCESS";
export const SHOW_USER_MESSAGE_ERROR = "SHOW_USER_MESSAGE_ERROR";
export const SHOW_USER_MESSAGE_LOADING = "SHOW_USER_MESSAGE_LOADING";

export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";
export const DELETE_MESSAGE_LOADING = "DELETE_MESSAGE_LOADING";

export const UPLOAD_USERFORM_LOADING = "UPLOAD_USERFORM_LOADING";
export const UPLOAD_USERFORM_SUCCESS = "UPLOAD_USERFORM_SUCCESS";
export const UPLOAD_USERFORM_ERROR = "UPLOAD_USERFORM_ERROR";
export const SET_USERFORMS = "SET_USERFORMS";

export const USERFORM_DELETE_SUCCESS = "USERFORM_DELETE_SUCCESS";
export const USERFORM_DELETE_LOADING = "USERFORM_DELETE_LOADING";
export const USERFORM_DELETE_ERROR = "USERFORM_DELETE_ERROR";

export const TRANSACTION_GET_SUCCESS = "TRANSACTION_GET_SUCCESS";
export const TRANSACTION_GET_ERROR = "TRANSACTION_GET_ERROR";
export const TRANSACTION_GET_LOADING = "TRANSACTION_GET_LOADING";

export const SEND_INTAKE_SUCCESS = "SEND_INTAKE_SUCCESS";
export const SEND_INTAKE_LOADING = "SEND_INTAKE_LOADING";
export const SEND_INTAKE_ERROR = "SEND_INTAKE_ERROR";

export const FETCH_CURRENT_MESSAGES_LOADING = "FETCH_CURRENT_MESSAGES_LOADING";
export const FETCH_CURRENT_MESSAGES_SUCCESS = "FETCH_CURRENT_MESSAGES_SUCCESS";
export const FETCH_CURRENT_MESSAGES_ERROR = "FETCH_CURRENT_MESSAGES_ERROR";

export const FETCH_URL_LOADING = "FETCH_URL_LOADING";
export const FETCH_URL_SUCCESS = "FETCH_URL_SUCCESS";
export const FETCH_URL_ERROR = "FETCH_URL_ERROR";

export const NOTIFICATION_INTAKEFORM_LOADING =
  "NOTIFICATION_INTAKEFORM_LOADING";
export const NOTIFICATION_INTAKEFORM_SUCCESS =
  "NOTIFICATION_INTAKEFORM_SUCCESS";
export const NOTIFICATION_INTAKEFORM_ERROR = "NOTIFICATION_INTAKEFORM_ERROR";

export const GET_USERFORM_SUCCESS = "GET_USERFORM_SUCCESS";
export const GET_USERFORM_ERROR = "GET_USERFORM_ERROR";
export const GET_USERFORM_LOADING = "GET_USERFORM_LOADING";

export const SEND_MESSAGE_SUCCESS_NOTIFY = "SEND_MESSAGE_SUCCESS_NOTIFY";

export const MESSAGE_TO_ADMIN_SUCCESS = "MESSAGE_TO_ADMIN_SUCCESS";
export const MESSAGE_TO_ADMIN_LOADING = "MESSAGE_TO_ADMIN_LOADING";
export const MESSAGE_TO_ADMIN_ERROR = "MESSAGE_TO_ADMIN_ERROR";
export const SET_TEMP_INTAKEFORM_ID = "SET_TEMP_INTAKEFORM_ID";

export const TRANSACTION_IS_VOIDABLE_LOADING = "TRANSACTION_IS_VOIDABLE_LOADING";
export const TRANSACTION_IS_VOIDABLE_SUCCESS = "TRANSACTION_IS_VOIDABLE_SUCCESS";
export const TRANSACTION_IS_VOIDABLE_ERROR = "TRANSACTION_IS_VOIDABLE_ERROR";

export const TRANSACTION_REFUND_LOADING = "TRANSACTION_REFUND_LOADING";
export const TRANSACTION_REFUND_SUCCESS = "TRANSACTION_REFUND_SUCCESS";
export const TRANSACTION_REFUND_ERROR = "TRANSACTION_REFUND_ERROR";

export const UPDATE_INTAKE_FORM_TYPE_SUCCESS = "UPDATE_INTAKE_FORM_TYPE_SUCCESS";
export const UPDATE_INTAKE_FORM_TYPE_LOADING = "UPDATE_INTAKE_FORM_TYPE_LOADING";
export const UPDATE_INTAKE_FORM_TYPE_ERROR = "UPDATE_INTAKE_FORM_TYPE_ERROR";


