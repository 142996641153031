import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import "./popover.css";
import {CrossIcon} from "../Icons";
import {withButtonLoader} from "../../helpers";

const CustomPopoverAddAdmin = ({
                                   title = "",
                                   show,
                                   doAddAdmin,
                                   close,
                                   setShowPopUp,
                                   isLoading,
                                   themeColor1
                               }) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const inputChangeHandler = (e) => {
        const target = e.target;
        const updateFormData = {
            ...formData,
            [target.name]: target.value,
        }
        setFormData(updateFormData);
    };


    const handleAddAdmin = (e) => {
        e.preventDefault();
        doAddAdmin(formData);
    };

    return (<>
        <div>
            <Modal show={show} size="md" centered className="update-successfully">
                <div data-cy="modal-pop-up" onClick={() => setShowPopUp(false)} className="close-modal-btn">
                    <CrossIcon/>
                </div>
                <Modal.Body>
                    <form>


                        <div className="modal-headr-text">
                            <h2 style={{marginBottom: "40px"}}>{title}</h2>


                            <div className="d-flex align-items-center pay-large-inner justify-content-between"
                                 style={{marginBottom: "20px"}}>
                                <label>First Name</label>
                                <input
                                    // style={{width: '200px'}}
                                    type="text"
                                    className="pay-control custom-input-border-css"
                                    // id={index}
                                    name={"firstName"}
                                    placeholder="First Name"
                                    value={formData["firstName"]}
                                    onChange={inputChangeHandler}
                                    aria-describedby="emailHelp"
                                    // onBlur={(e) => formatValue(e)}
                                    // value={getPayerAmount(index)}
                                    // onChange={(e) => onAmountChangeHandler(e, index)}
                                    // onClick={(e) => handleClick(e)}
                                />
                            </div>
                            <div className="d-flex align-items-center pay-large-inner justify-content-between"
                                 style={{marginBottom: "20px"}}>
                                <label>Last Name</label>
                                <input
                                    // style={{width: '200px'}}
                                    type="text"
                                    className="pay-control custom-input-border-css"
                                    name={"lastName"}
                                    placeholder="Last Name"
                                    value={formData["lastName"]}
                                    onChange={inputChangeHandler}
                                    // id={index}
                                    aria-describedby="emailHelp"
                                    // onBlur={(e) => formatValue(e)}
                                    // value={getPayerAmount(index)}
                                    // onChange={(e) => onAmountChangeHandler(e, index)}
                                    // onClick={(e) => handleClick(e)}
                                />
                            </div>
                            <div className="d-flex align-items-center pay-large-inner justify-content-between"
                                 style={{marginBottom: "20px"}}>
                                <label>Email</label>
                                <input
                                    // style={{width: '200px'}}
                                    type="text"
                                    className="pay-control custom-input-border-css"
                                    name={"email"}
                                    placeholder="Email"
                                    value={formData["email"]}
                                    onChange={inputChangeHandler}
                                    // id={index}
                                    aria-describedby="emailHelp"
                                    // onBlur={(e) => formatValue(e)}
                                    // value={getPayerAmount(index)}
                                    // onChange={(e) => onAmountChangeHandler(e, index)}
                                    // onClick={(e) => handleClick(e)}
                                />
                            </div>
                            <div className="d-flex align-items-center pay-large-inner justify-content-between"
                                 style={{marginBottom: "20px"}}>
                                <label>Phone</label>
                                <input
                                    // style={{width: '200px'}}
                                    type="text"
                                    className="pay-control custom-input-border-css"
                                    // id={index}
                                    aria-describedby="emailHelp"
                                    name={"phone"}
                                    placeholder="Phone"
                                    value={formData["phone"]}
                                    onChange={inputChangeHandler}
                                    // onBlur={(e) => formatValue(e)}
                                    // value={getPayerAmount(index)}
                                    // onClick={(e) => handleClick(e)}
                                />
                            </div>
                            <div className="flex flex-align-center flex-end" style={{marginTop: "40px"}}>
                                <button
                                    className="custom-popover-button  sos-custom-btn-css"
                                    onClick={close}>
                                    Cancel
                                </button>

                                <button
                                    className="custom-popover-button close-btn sos-custom-btn-css m-l-10"
                                    onClick={(e) => handleAddAdmin(e)}>
                                    {withButtonLoader("Add Admin", isLoading === true, themeColor1)}
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    </>);
};

export default CustomPopoverAddAdmin;
