import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {Button, ButtonGroup} from 'react-bootstrap';

import {
    DollarSignIcon, NewMessageIcon, TrashCanIcon, CheckBoxIcon, SendIcon,
} from "../../Components/Icons";
import {
    getAllAdmins,
    redirectPayUserForAdminLookup,
    getPayerUserById,
    getAdminUserData,
    deleteUserData,
    deleteAnAdmin,
    sendAdminLoginInvite,
    addAdminAction
} from "../../Actions/authActions";
import Table from "./../AdminDashboard/ReactTable/ReactTableMain";
import {subDomain, amountFormatterWithZero} from "../../utils";
import AbstractCustomPopover from "../../Components/CustomPopoverDelete/abstract";
import CustomePopUp from "../../Components/customePopUp";
import {DELETE_USERDATA_INITIAL} from "../../constants";
import "./payersTable.css";
import SendIntakeFormModal from "../../Components/SendIntakeFormModal";
import CustomPopoverAddAdmin from "../../Components/CustomPopoverAddAdmin";

const AllAdmins = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {themeColor1} = props || "";
    const params = useParams();
    const subdomain = params.subdomain || subDomain();

    const allAdmins = useSelector((state) => state.auth.allAdmins);
    const [allAdminsData, setAllAdminsData] = useState(allAdmins);

    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showPopUpAdminLoginInvite, setShowPopUpAdminLoginInvite] = useState(false);
    const [showPopUpAddAdmin, setShowPopUpAddAdmin] = useState(false);

    const [successPopupMessage, setSuccessPopupMessage] = useState('');

    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);
    const [itemForPopupDisplay, setitemForPopupDisplay] = useState({});
    const [adminToInvite, setAdminToInvite] = useState({});

    const [tablePropsForDeletion, setTablePropsForDeletion] = useState(null);

    const [isSendOpen, setIsSendOpen] = useState(false);
    const [intakeFormToSend, setIntakeFormToSend] = useState({});
    const [selectedPayer, setSelectedPayer] = useState(null);

    const deleteUserDataLoading = useSelector((state) => state.auth.deleteUserDataLoading);
    const deleteUserDataSuccess = useSelector((state) => state.auth.deleteUserDataSuccess);
    const deleteUserDataError = useSelector((state) => state.auth.deleteUserDataError);

    const adminLoginInviteLoading = useSelector((state) => state.auth.adminLoginInviteLoading);
    const adminLoginInviteSuccess = useSelector((state) => state.auth.adminLoginInviteSuccess);
    const adminLoginInviteError = useSelector((state) => state.auth.adminLoginInviteError);

    const addAdminLoading = useSelector((state) => state.auth.addAdminLoading);
    const addAdminSuccess = useSelector((state) => state.auth.addAdminSuccess);
    const addAdminError = useSelector((state) => state.auth.addAdminError);

    const adminUser = useSelector(state => state.auth.adminUser);
    const intakeforms = useSelector((state) => state.auth.adminUser.intakeforms);

    useEffect(() => {
        if (adminUser && !Object.keys(adminUser).length) {
            dispatch(getAdminUserData(subdomain));
            // dispatch(getMessage(subdomain));
            // dispatch(getUserforms(subdomain));
        }
    }, [!adminUser]);


    useEffect(() => {
        if (deleteUserDataSuccess) {
            setShowPopUpDelete(false);
            setShowSuccessPopUp(true);
            setSuccessPopupMessage("Deletion successful");
            dispatch(getAllAdmins(subdomain));
        }
        if (deleteUserDataError) {
            setShowPopUpDelete(false);
            setShowErrorPopUp(true);
        }
    }, [deleteUserDataSuccess, deleteUserDataError]);

    useEffect(() => {
        if (adminLoginInviteSuccess) {
            setShowPopUpAdminLoginInvite(false);
            setShowSuccessPopUp(true);
            setSuccessPopupMessage("Invitation sent");
            dispatch(getAllAdmins(subdomain));
        }
        if (adminLoginInviteError) {
            setShowPopUpAdminLoginInvite(false);
            setShowErrorPopUp(true);
        }
    }, [adminLoginInviteSuccess, adminLoginInviteError]);

    useEffect(() => {
        if (addAdminSuccess) {
            setShowPopUpAddAdmin(false);
            setShowSuccessPopUp(true);
            setSuccessPopupMessage("Admin added successfully");
            dispatch(getAllAdmins(subdomain));
        }
        if (addAdminError) {
            setShowPopUpAddAdmin(false);
            setShowErrorPopUp(true);
        }
    }, [addAdminSuccess, addAdminError]);

    useEffect(() => {
        dispatch(getAllAdmins(subdomain));
    }, []);

    useEffect(() => {
        setAllAdminsData(allAdmins);
    }, [allAdmins]);


    const handleDelete = (row, tableProps) => {
        setTablePropsForDeletion(tableProps);
        setShowPopUpDelete(true);
        setitemForPopupDisplay(row);
    }

    const handleAdminLoginInvite = (row) => {
        setShowPopUpAdminLoginInvite(true);
        setitemForPopupDisplay(row);

        // setAdminToInvite(props.row.original);
        // setIsSendOpen(true);

    }


    const handlePay = (row) => {
        dispatch(getPayerUserById({subdomain: subdomain, id: row.id}));
        dispatch(redirectPayUserForAdminLookup(row, subdomain, navigate));
    }


    const deleteAdmin = (itemForPopupDisplay) => {
        dispatch(deleteAnAdmin(itemForPopupDisplay._id, subdomain));
    };

    const adminLoginInvite = (itemForPopupDisplay) => {
        dispatch(sendAdminLoginInvite(itemForPopupDisplay._id, subdomain));
    };

    const handleClosePopUpDelete = () => {
        setShowPopUpDelete(false);
        setTablePropsForDeletion(null);
    }

    const handleClosePopUpAddAdmin = () => {
        setShowPopUpAddAdmin(false);
    }

    const handleClosePopUpAdminLoginInvite = () => {
        setShowPopUpAdminLoginInvite(false);
        setitemForPopupDisplay(null);
    }

    const handleSuccessClosePopUp = () => {
        setShowSuccessPopUp(false);

        const dataCopy = [...allAdmins];
        dataCopy.splice(tablePropsForDeletion, 1);
        setAllAdminsData(dataCopy);
        // setPayerForDeletion({});
        // setPayerForDeletionTotal("0.00");
        setTablePropsForDeletion(null);
        dispatch({type: DELETE_USERDATA_INITIAL});
    }

    const handleErrorClosePopUp = () => {
        setShowErrorPopUp(false);
        dispatch({type: DELETE_USERDATA_INITIAL});
    }

    const addAdmin = () => {
        setShowPopUpAddAdmin(true);
    };

    const actionsTable = (props, tableProps) => {

        return (<>
            {/*<table>*/}
            {/*<tbody>*/}
            {/*<tr  style={{width: "6vw"}}>*/}
            <div style={{display: "flex", justifyContent: "space-around"}} key={props.row.id}>
                {<>
                    <div
                        className="text-center"
                        style={{
                            cursor: "pointer", backgroundColor: "inherit",
                        }}
                        onClick={() => handleAdminLoginInvite(props.row.original)}
                    >
                        <SendIcon color={themeColor1} height="1.4vw" width="1.4vw"/>
                        <div>Send Invitation</div>
                    </div>
                    <div
                        className="text-center"
                        style={{
                            cursor: "pointer", backgroundColor: "inherit",
                        }}
                        onClick={() => handleDelete(props.row.original, tableProps)}
                    >
                        <TrashCanIcon color={themeColor1} height={"1.4vw"}/>
                        <div>Delete</div>
                    </div>
                </>}
            </div>
            {/*</tbody>*/}
            {/*</table>*/}
        </>)
    }

    const columns = React.useMemo(() => [
        // David
        {
            Header: "Admin",
            colWidthWeight: 7,
            accessor: (row) => {
                return `${row.firstName}, ${row.lastName}`
            },
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => `${props.row.original.firstName}, ${props.row.original.lastName}`,
        },
        {
            Header: "Email",
            colWidthWeight: 15,
            id: "email",
            disableFilters: true,
            disableSortBy: true,
            accessor: (row) => row.email,
            Cell: (props) => props.row.original.email,
        },
        {
            Header: "Phone",
            colWidthWeight: 8,
            id: "phone",
            disableFilters: true,
            disableSortBy: true,
            accessor: (row) => row.phone,
            Cell: (props) => props.row.original.phone,
        },
        {
            Header: "Actions",
            colStyle: {"textAlign": "center"},
            colWidthWeight: 15,
            disableFilters: true,
            disableSortBy: true,
            accessor: row => row.id,
            Cell: (props, tableProps) => (actionsTable(props, tableProps)),
        }], [allAdmins]);

    const colWidths = columns.map(o => o.colWidthWeight).map(x => x.toString() + "%");

    const labels = {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
    };

    const doAddAdmin = (newAdminData) => {
        dispatch(addAdminAction(newAdminData));
    }

    const HtmlTableDisplayItem = ({itemForPopupDisplay}) => {
        return (<>
            <table className="modal-body-text-size modal-table table-responsive table-sm">
                <tbody>
                {itemForPopupDisplay && Object.entries(itemForPopupDisplay).map(([key, value], index) => {
                    if (!['firstName', 'lastName', 'email', 'phone'].includes(key)) return;
                    return <tr key={index}>
                        <td>{labels[key]}</td>
                        <td>{value}</td>
                    </tr>

                })}
                </tbody>
            </table>
        </>)
    };



    return (<>
        <div className=" container-fluid">
            {<div style={{display: "flex", marginBottom: "20px"}}>
                {<div
                    style={{display: "inline-block", marginLeft: "auto"}}
                    className={`sos-custom-btn-new-message sos-secondary-btn-css mb-1 w-25 float-right`}
                    onClick={() => addAdmin()}
                >
                    {"Add Admin"}
                </div>}
            </div>}
            {showPopUpDelete && <AbstractCustomPopover
                actionButtonText={"Delete"}
                title={"Delete Admin?"}
                subtext={"The following admin record will be removed from the portal."}
                show={showPopUpDelete}
                isLoading={deleteUserDataLoading}
                close={handleClosePopUpDelete}
                themeColor1={themeColor1}
                callAction={deleteAdmin}
                itemForPopupDisplay={itemForPopupDisplay}
                HtmlTableDisplayItem={HtmlTableDisplayItem}
            />}
            {showPopUpAdminLoginInvite && <AbstractCustomPopover
                actionButtonText={"Send Email Invitation"}
                title={"Send login invitation"}
                subtext={"The following admin will be sent a login invitation via email."}
                // show={showPopUpDelete}
                show={showPopUpAdminLoginInvite}
                // isLoading={deleteUserDataLoading}
                isLoading={adminLoginInviteLoading}
                close={handleClosePopUpAdminLoginInvite}
                themeColor1={themeColor1}
                // callDeleteAction={deleteAdmin}
                callAction={adminLoginInvite}
                itemForPopupDisplay={itemForPopupDisplay}
                // adminToInvite={adminToInvite}
                HtmlTableDisplayItem={HtmlTableDisplayItem}
            />}

            {(showPopUpAddAdmin &&
                <CustomPopoverAddAdmin
                    title={"Add Admin"}
                    show={showPopUpAddAdmin}
                    isLoading={addAdminLoading}
                    doAddAdmin={doAddAdmin}
                    close={handleClosePopUpAddAdmin}
                    setShowPopUp={setShowPopUpAddAdmin}
                    themeColor1={themeColor1}
                />)}
            {showSuccessPopUp ? (<CustomePopUp
                message={successPopupMessage}
                openModal={showSuccessPopUp}
                onCloseModal={handleSuccessClosePopUp}
            />) : ""}

            {showErrorPopUp ? (<CustomePopUp
                message={"Error"}
                openModal={showErrorPopUp}
                onCloseModal={handleErrorClosePopUp}
            />) : ""}
            {allAdmins && <Table
                columns={columns}
                data={allAdmins}
                colWidths={colWidths}
                noDataMessage={"No data"}
            />}
            {selectedPayer && <SendIntakeFormModal
                selectedPayer={selectedPayer}
                adminUser={adminUser}
                intakeFormToSend={intakeFormToSend}
                setIntakeFormToSend={setIntakeFormToSend}
                intakeforms={adminUser?.intakeforms}
                openModal={isSendOpen}
                onCloseModal={() => {
                    setSelectedPayer(null)
                    setIsSendOpen(false)
                }}
                subdomain={subdomain}
            />}
        </div>
    </>);
};
export default AllAdmins;
