import React, {useEffect, useRef, useState} from "react";
import {SoButton} from "../../UI/Button/so-button";
import "./sign-document.css";
import {DocusealForm} from "@docuseal/react";
import {addToUserforms} from "../../../Actions/authActions";
import {useDispatch} from "react-redux";


export const SignDocument = ({doc, close, subdomain, sosCustomerId, userId, userEmail}) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const dispatch = useDispatch();


    const upload = async () => {
        dispatch(addToUserforms({
            userFormData: data,
            subdomain, intakeformId: doc._id, sosCustomerId, userId,
            originalname: doc.originalname
        }, subdomain, doc._id));
        close();
    };

    return (
        <div className={'prepare-document-container'}>
            <div className={'header'}>
                <h4>Signing {doc.originalname.slice(0, 20)}</h4>
                <div className={'header-action'}>
                    <div>
                        <button
                            className="sos-custom-btn-new-message sos-secondary-btn-css so-btn"
                            onClick={() => close()}>
                            Cancel
                        </button>
                    </div>
                    <div>
                        <SoButton onClick={() => upload()} text={'Submit'}></SoButton>
                    </div>
                </div>
            </div>
            <div className={'document-container'}>
                <div className={'viewer-container'}>
                    <div className={'p-10'}>
                        Instructions: Fill in the required fields, then submit the document.
                    </div>

                    <DocusealForm
                        src={`https://docuseal.sosoftpay.com/d/${doc.slug}`}
                        email={userEmail}
                        externalId={userId}
                        onComplete={async (data) => {
                            setData(data);
                        }}
                        withSendCopyButton={false}
                        customCss={`
                        div > div > div.flex.sticky.top-0.z-50.space-x-2.items-center.bg-yellow-100.p-2.border-y.border-yellow-200 {display: none;}
                        #scrollbox > div > div.mt-4.flex{display: none;}
                        #scrollbox {color: #000;}
                        #form_container {color: #000;}
                        div > div > div.max-w-md.mx-auto.px-2.mt-12.mb-4 > div > div.space-y-6 > div.text-center.w-full.space-y-4 {display: none;}
                        `}
                    />
                </div>
            </div>
            {error && <div className={'error'}>
                {error}
            </div>}
        </div>
    );
};
