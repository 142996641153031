import React, {useEffect, useState} from "react";
import {Modal, Overlay, Popover} from "react-bootstrap";
import "./popover.css";
import {CrossIcon} from "../Icons";
import map from "lodash/map";
import {amountFormatterWithZero} from "../../utils";
import sumBy from "lodash/sumBy";
import {withButtonLoader} from "../../helpers";

const CustomPopoverDelete = ({
                                 title = "",
                                 subtext,
                                 show,
                                 target,
                                 isVoidable,
                                 close,
                                 isLoading,
                                 themeColor1,
                                 deletePayer,
                                 payerForDeletion,
                                 payerForDeletionTotal,
                             }) => {

    // const [patientPayerInfo, setPatientPayerInfo] = useState([]);

    useEffect(() => {
        // if (transaction.patientPayer) {
        //     setPatientPayerInfo(transaction.patientPayer.map(o => {
        //         return {...o, amountRefunded: o.amountPaid};
        //     }));
        // }

    }, [payerForDeletion]);

    const handleClick = (e) => {
        e.target.select();
    };

    const doDeletePayer = () => {
        deletePayer(payerForDeletion);
    };


    return (<>
        <div>
            <Modal show={show} size="md" centered className="update-successfully">
                <div data-cy="modal-pop-up" onClick={() => close()} className="close-modal-btn">
                    <CrossIcon/>
                </div>
                <Modal.Body>
                    <div className="modal-text">
                        <h2 className="modal-title">{title}</h2>
                        <p className="modal-title">{subtext}</p>
                        <div className="mb-3"></div>

                        <table className="modal-body-text-size modal-table table-responsive table-sm">
                            <tr>
                                <td>Name</td>
                                <td>{`${payerForDeletion.payerFirst} ${payerForDeletion.payerLast}`}</td>
                            </tr>
                            <tr>
                                <td>Payer ID</td>
                                <td>{payerForDeletion.payerId}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{payerForDeletion.payerEmail}</td>
                            </tr>
                            <tr>
                                <td>Mobile</td>
                                <td>{payerForDeletion.payerCell}</td>
                            </tr>
                            <tr>
                                <td>Total Due</td>
                                <td>${payerForDeletionTotal}</td>
                            </tr>
                            <tr className="border-top">
                                <td colSpan="2" className="cell-padding"></td>
                            </tr>
                            {payerForDeletion.patientPayer && payerForDeletion.patientPayer.map((patientPayer) => {
                                return (
                                    <>
                                        <tr>
                                            <td>Patient Name</td>
                                            <td>{`${patientPayer.patientFirst} ${patientPayer.patientLast}`}</td>
                                        </tr>
                                        <tr>
                                            <td>Patient Number</td>
                                            <td>{patientPayer.patientNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{patientPayer.patientEmail}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile</td>
                                            <td>{patientPayer.patientCell}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount Due</td>
                                            <td>${patientPayer.accountBalance}</td>
                                        </tr>
                                        <tr className="border-top"><td colSpan="2" className="cell-padding"></td></tr>
                                    </>
                                )
                            })}
                        </table>
                        <div className="flex flex-align-center flex-end">
                            <button
                                className="custom-popover-button  sos-custom-btn-css"
                                onClick={close}
                            >
                                Cancel
                            </button>

                            <button
                                className="custom-popover-button close-btn sos-custom-btn-css m-l-10"
                                onClick={() => doDeletePayer()}
                            >
                                {withButtonLoader("Delete", isLoading === true, themeColor1)}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    </>);
};

export default CustomPopoverDelete;
