import React, {useState, useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import get from "lodash/get";
import {
    uploadMultipleFiles,
    getPayerUserForAdminLookup,
    savePaymentsDataAdmin,
    deleteCard,
    getAdminUserData,
    getMessage,
    sendMesssageToUser,
    adminHardDeleteMessage,
    getUserforms,
} from "../../Actions/authActions";
import {subDomain, eventUrl} from "../../utils";
import AdminPayerUser from "./AdminPayerUser";
// import Transactions from "../Transactions";
import TransactionsTable from "./TransactionsTable";
import AllPayers from "./PayersTable";
import AllAdmins from "./AllAdmins";
import "./AdminDashboard.css";
import {Helmet} from "react-helmet";
import OfficeSetting from "./OfficeSettting";
import AdminHome from "./AdminHome";
import AdminHeader from "./AdminHeader";
import Socket from "../../socketHelper";
import Forms from "./Forms";

const AdminDashboard = (props) => {
    const {match} = props || {};
    const params = useParams();
    const subdomain = params.subdomain || subDomain();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let payerUser = useSelector((state) => state.auth.payerUser);
    const isType = JSON.parse(localStorage.getItem("user"));
    const logoLoading = useSelector((state) => state.auth.uploadLogoLoading);
    const uploadFileLoading = useSelector(
        (state) => state.auth.uploadFileLoading
    );
    const userAdmin = useSelector((state) => state.auth.adminUser);
    const themeColor1 = get(userAdmin, "theme.themeColor1") || "#6e86c4";
    const themeColor2 = get(userAdmin, "theme.themeColor2") || "#94bbad";
    const themeColor3 = get(userAdmin, "theme.themeColor3");


    let globalCustomCSSWithTheme;
    globalCustomCSSWithTheme = `.sos-mack-btn:hover .css-15llytj span {background: ${themeColor1}} .dropzone-loader .css-15llytj span {background: ${themeColor1}} .dropzone-loader:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}} .nav-list-item a:hover {border-bottom: 3px solid ${
        themeColor1 ? themeColor1 : "#6e86c4"
    }} .sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-mack-btn:hover .css-15llytj span  {background: ${themeColor1}} .nav-list-selected {border-bottom: 3px solid ${
        themeColor1 ? themeColor1 : "#6e86c4"
    }}  .loader-helloSign .css-15llytj span {background: ${themeColor1}} .message-received-text {color: ${themeColor1}} .message-received-text:hover {color: ${themeColor1}} .nav-item:hover {box-shadow: 0 0 0 3px ${
        themeColor1 ? themeColor1 : "#6e86c4"
    } !important;} .nav-item.active {box-shadow: 0 0 0 3px ${
        themeColor1 ? themeColor1 : "#6e86c4"
    } !important;} .custom-radio .custom-radio-checked:after {background: ${
        themeColor1 ? themeColor1 : "#6e86c4"
    }} .custom-checkbox {border: 1px solid ${
        themeColor1 ? themeColor1 : "#6e86c4"
    }} .custom-checkbox .custom-checkbox-checked:after {border: solid ${
        themeColor1 ? themeColor1 : "#6e86c4"
    }; border-width: 0 3px 3px 0;} .text-cerulean {color:${themeColor1}} .custom-input-border-css {border: 1px solid ${
        themeColor2 ? themeColor2 : "#94bbad"
    }} .sos-content .custom-input-border-css {border: 1px solid ${
        themeColor2 ? themeColor2 : "#94bbad"
    }} .payment-raound {color:${themeColor1}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2} !important; border: ${themeColor2}} .pay-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2} !important; border: ${themeColor2}}`;

    const {logoUrl} = userAdmin || "";
    const logoName = logoUrl;
    const [showPopUp, setShowPopUp] = useState(false);
    const [payerEmail, setPayerEmail] = useState("");
    const [payerName, setPayerName] = useState("");
    const [payerCell, setPayerCell] = useState("");
    const [patientNumber, setPatientNumber] = useState("");
    const [dragFile, setDragFile] = useState(false);
    const [dragDoc, setDragDoc] = useState(false);
    const [intakePopUp, setIntakePopUp] = useState(false);
    const [logoDrag, setLogoDrag] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState("");

    // const editHelloSignUrls = useSelector((state) => state
    //   && state.auth.adminUser
    //   && state.auth.adminUser.intakeforms
    //   && state.auth.adminUser.intakeforms.map(intakeform => intakeform && intakeform.template && intakeform.template.edit_url)
    // // );
    const currentPage =
        window.location.href.split("/").slice(-1)[0] || "adminDashboard";

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: "instant"});
        }, 500);
    }, []);

    useEffect(() => {
        if (!Object.keys(userAdmin).length && currentPage === "adminDashboard") {
            dispatch(getAdminUserData(subdomain));
            dispatch(getMessage(subdomain));
            dispatch(getUserforms(subdomain));
        }
    }, [!userAdmin]);

    useEffect(() => {
        if (isType?.type === "user") {
            navigate(`${eventUrl(subdomain)}home`);
        }
    }, [isType]);

    useEffect(() => {
        if (Socket) {
            Socket.on(`userIntake_${subdomain}`, () => {
                dispatch(getUserforms(subdomain));
            });
            Socket.on(`sendMessageToAdmin_${subdomain}`, () => {
                dispatch(getMessage(subdomain));
            });
        }
    }, [Socket]);

    const dragOver = (e, type) => {
        e.preventDefault();
        if (type === "doc") {
            setDragDoc(true);
        } else if (type === "logo") {
            setLogoDrag(true);
        } else {
            setDragFile(true);
        }
    };
    const dragLeave = (e, type) => {
        e.preventDefault();
        if (type === "doc") {
            setDragDoc(false);
        } else if (type === "logo") {
            setLogoDrag(false);
        } else {
            setDragFile(false);
        }
    };

    const handleFiles = (files, j, isDocument) => {
        for (let i = 0; i < files.length; i++) {
            if (files[i]) {
                props
                    .uploadMultipleFiles(
                        files[i],
                        userAdmin && userAdmin._id,
                        isDocument,
                        subdomain
                    )
                    .then(() => {
                        setDragFile(false);
                        setDragDoc(false);
                        if (isDocument === "intake") {
                            setIntakePopUp(true);
                            setPopUpMessage(
                                "Form uploaded successfully. Please select the edit icon and follow the prompts if you wish to enable this form for electronic signatures."
                            );
                        }
                    });
            }
        }
    };

    const fileDrop = (e, i, isDocument) => {
        setDragFile(false);
        setDragDoc(false);
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFiles(files, i, isDocument);
    };

    const validateFile = (file) => {
        const fileName = get(file, "name");
        const re = /(\.pdf|\.doc|\.docx)$/i;
        if (re.exec(fileName)) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div>
                <div className="intakeform">
                    <Helmet style={[{cssText: `${globalCustomCSSWithTheme}`}]}/>
                    <AdminHeader
                        logoName={logoName}
                        subdomain={subdomain}
                        userAdmin={userAdmin}
                        dispatch={dispatch}
                        navigate={navigate}
                        themeColor1={themeColor1}
                        currentPage={currentPage}
                        setPayerEmail={setPayerEmail}
                        setPayerCell={setPayerCell}
                        setPatientNumber={setPatientNumber}
                    />
                    <div className="tab-content" id="pills-tabContent">
                        {currentPage === "settings" && <div
                            className={` tab-pane ${
                                currentPage === "settings" ? "fade show active" : ""
                            }`}
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <OfficeSetting
                                logoName={logoName}
                                subdomain={subdomain}
                                dragOver={dragOver}
                                dragLeave={dragLeave}
                                logoLoading={logoLoading}
                                userAdmin={userAdmin}
                                setLogoDrag={setLogoDrag}
                                logoDrag={logoDrag}
                                currentPage={currentPage}
                            />
                        </div>}
                        {currentPage === "adminDashboard" && <div
                            className={` tab-pane ${
                                currentPage === "adminDashboard" ? "fade show active" : ""
                            }`}
                        >
                            <AdminHome
                                themeColor1={themeColor1}
                                themeColor3={themeColor3}
                                subdomain={subdomain}
                                patientNumber={patientNumber}
                                userAdmin={userAdmin}
                                fileDrop={fileDrop}
                                dragOver={dragOver}
                                dragLeave={dragLeave}
                                dragDoc={dragDoc}
                                setIntakePopUp={setIntakePopUp}
                                intakePopUp={intakePopUp}
                                dragFile={dragFile}
                                popUpMessage={popUpMessage}
                                setPopUpMessage={setPopUpMessage}
                                showPopUp={showPopUp}
                                setShowPopUp={setShowPopUp}
                                setDragDoc={setDragDoc}
                                setDragFile={setDragFile}
                                payerUser={payerUser}
                                payerEmail={payerEmail}
                                setPayerEmail={setPayerEmail}
                                payerName={payerName}
                                setPayerName={setPayerName}
                                setPayerCell={setPayerCell}
                                payerCell={payerCell}
                                currentPage={currentPage}
                            />
                        </div>}
                        {currentPage === "transactions" && <div
                            className={` tab-pane ${
                                currentPage === "transactions" ? "fade show active" : ""
                            }`}
                        >
                            <div className="intake-content-admin">
                                <TransactionsTable
                                    homePage={currentPage}
                                    subdomain={subdomain}
                                    themeColor1={themeColor1}
                                    userAdmin={userAdmin}
                                />
                            </div>
                        </div>}
                        {currentPage === "forms" && <div
                            className={` tab-pane ${
                                currentPage === "forms" ? "fade show active" : ""
                            }`}
                        >
                            <div className="intake-content-admin">
                                {/*{userAdmin && userAdmin._id && <Forms*/}
                                <Forms
                                    homePage={currentPage}
                                    subdomain={subdomain}
                                    themeColor1={themeColor1}
                                    userAdmin={userAdmin}
                                    uploadMultipleFiles={props.uploadMultipleFiles}
                                />
                            </div>
                        </div>}
                        {currentPage === "allPayers" && <div
                            className={` tab-pane ${
                                currentPage === "allPayers" ? "fade show active" : ""
                            }`}
                        >
                            <div className="intake-content-admin">
                                <AllPayers
                                    homePage={currentPage}
                                    subdomain={subdomain}
                                    themeColor1={themeColor1}
                                    userAdmin={userAdmin}
                                />
                            </div>
                        </div>}

                        {currentPage === "allAdmins" && <div
                            className={` tab-pane ${
                                currentPage === "allAdmins" ? "fade show active" : ""
                            }`}
                        >
                            <div className="intake-content-admin">
                                <AllAdmins
                                    homePage={currentPage}
                                    subdomain={subdomain}
                                    themeColor1={themeColor1}
                                    userAdmin={userAdmin}
                                />
                            </div>
                        </div>}

                        {currentPage === "payments" && <div
                            className={` tab-pane ${
                                currentPage === "payments" ? "fade show active" : ""
                            }`}
                        >

                            <AdminPayerUser
                                payerUser={payerUser}
                                userAdmin={userAdmin}
                                logoLoading={logoLoading}
                                uploadFileLoading={uploadFileLoading}
                                match={match}
                                getPayerUserForAdminLookup={getPayerUserForAdminLookup}
                                savePaymentsDataAdmin={savePaymentsDataAdmin}
                                deleteCard={deleteCard}
                                themeColor1={themeColor1}
                                currentPage={currentPage}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = {
    getPayerUserForAdminLookup,
    savePaymentsDataAdmin,
    uploadMultipleFiles,
    sendMesssageToUser,
    adminHardDeleteMessage,
};

export default connect(null, mapDispatchToProps)(AdminDashboard);
