import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CrossIcon, DownArrowIcon} from "../../Components/Icons";
import {
    updateAdminData,
    uploadLogo,
    getAdminUserData,
} from "../../Actions/authActions";
import ThemeSetting from "../../Components/ThemeSetting";
import {withButtonLoader} from "../../helpers";
import classNames from "classnames";
import map from "lodash/map";
import {useNavigate} from "react-router-dom";
import "./AdminDashboard.css";
import Socket from "./../../socketHelper";
import CustomCheckbox from "../../Components/CustomCheckBox";

const OfficeSetting = (props) => {
    const {
        logoName,
        subdomain,
        dragOver,
        dragLeave,
        logoLoading,
        userAdmin,
        setLogoDrag,
        logoDrag,
        currentPage,
    } = props;
    useEffect(() => {
        if (!Object.keys(userAdmin).length && currentPage === "settings") {
            dispatch(getAdminUserData(subdomain));
        }
    }, [!userAdmin]);

    useEffect(() => {
        if (Socket) {
            Socket.on(`adminUpdated`, () => {
            });
        }
    }, [Socket]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let theme = {};

    const [updatedColor, setUpdatedColor] = useState({});

    const isUpdateAdminLoading = useSelector(
        (state) => state.auth.isUpdateAdminLoading
    );
    const handleColorSave = (hexColor) => {
        setUpdatedColor(hexColor);
    };

    const [themeOpts, setThemeOpts] = useState([
        {
            key: 0,
            from: "theme",
            name: "themeColor1",
            label: "Pick your main color",
            defaultValue: "",
            isOpen: false,
        },
        {
            key: 1,
            from: "theme",
            name: "themeColor2",
            label: "Pick your secondary color",
            defaultValue: "",
            isOpen: false,
        },
        {
            key: 2,
            from: "theme",
            name: "themeColor3",
            label: "Pick your accent color",
            defaultValue: "",
            isOpen: false,
        },
    ]);
    const [formData, setFormData] = useState({});
    const [isDirty, setIsDirty] = useState(false);
    const [formCheckboxData, setFormCheckboxData] = useState({});
    const user = JSON.parse(localStorage.getItem("user"));
    const [errorMessage, setErrorMessage] = useState("");

    const inputLogo = useRef(null);

    useEffect(() => {
        if (!isDirty) {
            setFormData({
                officeName: userAdmin.officeName,
                publicEmail: userAdmin.publicEmail,
                publicPhone: userAdmin.publicPhone,
                timeZone: userAdmin.timeZone,
            });
        }
        setFormCheckboxData({
            displayStaffTab: userAdmin.displayStaffTab,
            messageNotification: userAdmin.messageNotification,
            transactionNotification: userAdmin.transactionNotification,
            transactionSummaryNotification: userAdmin.transactionSummaryNotification,
        });
    }, [userAdmin]);

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            setFormCheckboxData({
                ...formCheckboxData,
                [e.target.name]: e.target.checked,
            });
        } else {
            setIsDirty(true);
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const removeLogoName = () => {
        const logoUpload = {...userAdmin};
        logoUpload.logoUrl = "";
        dispatch(updateAdminData(logoUpload));
    };

    const validateLogo = (file) => {
        const validTypes = ["image/jpeg", "image/png"];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    };

    const logofileDrop = (e) => {
        setLogoDrag(false);
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (validateLogo(file)) {
            dispatch(uploadLogo(file, user && user._id, subdomain));
        } else {
            setErrorMessage("Couldn't upload logo accept .png and .jpeg");
        }
    };

    const onUploadFileLogo = (event) => {
        const file = event.target.files[0];
        if (validateLogo(file)) {
            dispatch(uploadLogo(file, user && user._id, subdomain));
        } else {
            setErrorMessage("Couldn't upload logo accept .png and .jpeg");
        }
    };
    const onButtonUploadLogo = (e) => {
        e.preventDefault();
        inputLogo.current.click();
    };
    const saveData = () => {
        map(updatedColor, (i) => {
            theme[i.name] = i.defaultValue;
        });
        setIsDirty(true);
        setFormData(formData);
        if (formData) {
            const data = {
                ...formData,
                ...formCheckboxData,
                theme,
            };
            dispatch(updateAdminData(data, navigate));
        }
    };
    return (
        <div className="intake-content-admin">
            <div className="jumbotron p-4 p-lg-5">
                <div className="Office-content pb-3">
                    <div>
                        <div className="font-weight-bold head-font">Office Set Up</div>
                        <div className=" mt-2 mb-3 row">
                            <div className="col-md-3 col-lg-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="office-form-label"
                                >
                                    Office Name
                                </label>

                                <input
                                    className="office-label custom-input-border-css"
                                    id="officeName"
                                    name="officeName"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Office Name"
                                    value={(formData && formData.officeName) || ""}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="office-form-label"
                                >
                                    Email Address
                                </label>
                                <input
                                    className="office-label custom-input-border-css"
                                    id="publicEmail"
                                    name="publicEmail"
                                    aria-describedby="emailHelp"
                                    value={(formData && formData.publicEmail) || ""}
                                    placeholder="Enter Office Email Address"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="office-form-label"
                                >
                                    Phone Number
                                </label>
                                <input
                                    className="office-label custom-input-border-css input-placeholder"
                                    id="publicPhone"
                                    name="publicPhone"
                                    aria-describedby="emailHelp"
                                    value={(formData && formData.publicPhone) || ""}
                                    placeholder="Enter Office Phone Number"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-md-3 col-lg-3 timeZone-dropdown">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="office-form-label"
                                >
                                    Time Zone
                                </label>
                                <select
                                    className="timezone-selector office-label officesetup-select custom-input-border-css"
                                    name="timeZone"
                                    id="eventsCalTimezone"
                                    placeholder="choose Time Zone"
                                    value={(formData && formData.timeZone) || ""}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value="" disabled>
                                        Choose Time Zone
                                    </option>
                                    <option value="America/New_York">(UTC-05:00) Eastern</option>
                                    <option value="America/Chicago">(UTC-06:00) Central</option>
                                    <option value="America/Denver">(UTC-07:00) Mountain</option>
                                    <option value="America/Phoenix">
                                        (UTC-07:00) Mountain - AZ
                                    </option>
                                    <option value="America/Los_Angeles">
                                        (UTC-08:00) Pacific
                                    </option>
                                    <option value="America/Anchorage">(UTC-09:00) Alaskan</option>
                                    <option value="Pacific/Honolulu">(UTC-10:00) Hawaiian</option>
                                </select>
                                <div className="timeZoneDown-arrow">
                                    <DownArrowIcon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkbox-list mb-4">
                    <div>
                        <label></label>
                    </div>

                    <div className="officeSetup-checkbox">
                        <label className="officesetup-custom-checkbox">
                            <CustomCheckbox
                                checked={formCheckboxData.displayStaffTab || ""}
                                name="displayStaffTab"
                                onChange={(e) => handleChange(e)}
                            />
                            <span>Display Staff tab</span>
                        </label>
                    </div>
                </div>
                <div className="checkbox-list mb-4">
                    <div>
                        <label>Receive email notifications for:</label>
                    </div>

                    <div className="officeSetup-checkbox">
                        <label className="officesetup-custom-checkbox">
                            <CustomCheckbox
                                checked={formCheckboxData.messageNotification || ""}
                                name="messageNotification"
                                onChange={(e) => handleChange(e)}
                            />
                            <span>Each message</span>
                        </label>
                        <label className="officesetup-custom-checkbox">
                            <CustomCheckbox
                                checked={formCheckboxData.transactionNotification || ""}
                                name="transactionNotification"
                                onChange={(e) => handleChange(e)}
                            />
                            <span>Each transaction</span>
                        </label>
                        <label className="officesetup-custom-checkbox">
                            <CustomCheckbox
                                checked={formCheckboxData.transactionSummaryNotification || ""}
                                name="transactionSummaryNotification"
                                onChange={(e) => handleChange(e)}
                            />
                            <span>Daily transaction summary</span>
                        </label>
                    </div>
                </div>
                <div className="d-flex justify-content-between row">
                    <div className=" documents-card office-card center-message">
                        <div className=" card-hoiz-text d-flex justify-content-between">
                            <div className="card-head-font mb-1">Logo Upload</div>

                            <div className="card-head-right-font col-6 text-center">
                                <img src="/images/logo.png" className="img-fluid" alt="Logo"/>
                            </div>
                        </div>
                        <div className="col-7 ml-2 pl-0">
                            <h5 className="card-head-logo-font">
                                {`Upload your latest logo, to replace the website's current logo.`}
                            </h5>
                        </div>
                        {logoName && (
                            <div
                                className="upload-doc-scroll mt-3"
                                style={{height: "164px"}}
                            >
                                <div className="logoname-display">
                                    <div>
                                        <div className="d-flex  justify-content-between">
                                            <div className="col-8 pl-0">
                                                <h5 className="card-inner-text-font item_name  ">
                                                    {logoName}
                                                </h5>
                                            </div>

                                            <div
                                                className="close-icon"
                                                onClick={(e) => removeLogoName(e)}
                                            >
                                                <CrossIcon/>
                                            </div>
                                        </div>
                                        <div className="divider"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className={classNames(
                                logoName ? "drag-sub-div-dash-without" : "drag-sub-div-dash"
                            )}
                        >
                            <div
                                className="card-inner-drag-drop"
                                onDrop={(a) => logofileDrop(a)}
                                onDragOver={(e) => dragOver(e, "logo")}
                                onDragLeave={(e) => dragLeave(e, "logo")}
                            >
                                <div className="dropzone dropzone-loader">
                                    <>
                                        {logoLoading ? (
                                            withButtonLoader("", logoLoading, "#000000")
                                        ) : (
                                            <>
                                                {logoDrag ? (
                                                    withButtonLoader("", logoDrag, "#6e86c4")
                                                ) : (
                                                    <>
                                                        <p className="card-drag-drop-p mb-0">
                                                            Drag and Drop file here to upload
                                                        </p>
                                                        <div className=" justify-content-center">
                                                            <input
                                                                type="file"
                                                                id="file"
                                                                name="logoUpload"
                                                                style={{display: "none"}}
                                                                onChange={(e) => onUploadFileLogo(e)}
                                                                ref={inputLogo}
                                                            />

                                                            <button
                                                                type="button"
                                                                className="sos-browse-btn p-2 sos-custom-btn-css"
                                                                onClick={(e) => onButtonUploadLogo(e)}
                                                            >
                                                                Browse Files
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                </div>
                            </div>
                            <div className="text-center">
                                <p className="max-size mb-0">Max size (3MB)</p>
                                <span className="color-text max-size">{errorMessage}</span>
                            </div>
                        </div>
                    </div>
                    <ThemeSetting
                        userAdmin={userAdmin}
                        themeOpts={themeOpts}
                        setThemeOpts={setThemeOpts}
                        handleColorSave={handleColorSave}
                    />
                </div>
                <div className="center-btn save-btn">
                    <button
                        type="submit"
                        className="sos-custom-btn float-end sos-custom-btn-css"
                        onClick={() => saveData(formData)}
                        disabled={isUpdateAdminLoading}
                    >
                        {withButtonLoader("Save", isUpdateAdminLoading)}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OfficeSetting;
