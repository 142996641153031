import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParameterByName } from "../../helpers";
import {
  adminVerificationAccessCode,
  getAdminLoginAuthenticateCode,
  getLogo,
} from "../../Actions/authActions";
import { subDomain } from "../../utils";
import Toast from "../../Components/Toast";
import { map, get } from "lodash";
import { Helmet } from "react-helmet";

const AdminAccessCode = (props) => {
  const { match } = props || {};
  // const { params } = match || {};
  const params = useParams();
  const subdomain = params.subdomain || subDomain();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cname = getParameterByName("cname") || "";
  const { createdUser, isAdmin } = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : false;
  const isAdminLoginCodeSuccess = useSelector(
    (state) => state.auth.isAdminLoginCodeSuccess
  );
  const [emailAndPhoneMessage, setEmailAndPhoneMessage] = useState("email");
  const [emailData, setEmailData] = useState({});
  const [editableData, setEditableData] = useState({
    code: cname.split(" ")[0],
  });
  const logoUrl = localStorage.getItem("logoUrl");
  const theme = JSON.parse(localStorage.getItem("theme"));
  const themeColor1 = get(theme, "themeColor1");
  const themeColor2 = get(theme, "themeColor2");
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [error, setError] = useState({
    toastOpenClose: false,
    errorMsg: "",
  });
  let globalCustomCSSWithTheme;
  globalCustomCSSWithTheme = `.sos-custom-btn:hover .css-15llytj span {background: ${themeColor1}} .sos-custom-btn-css {background:${themeColor1};  border: 1px solid  ${themeColor1} } .sos-custom-btn-css:hover {color: ${themeColor1}; border: 1px solid ${themeColor1}}
  .text-cerulean {color:${themeColor1}} .text-cerulean:hover {color:${themeColor1}} .sos-content .form-control {border: 1px solid ${themeColor2}} .sos-content .form-control:focus {box-shadow: 0 0 0 0.15rem ${themeColor2}; border: ${themeColor2} }  `;
  const [fieldsList, setFieldsList] = useState([
    {
      key: "code",
      type: "text",
      title: "Enter the admin code you received",
      placeholder: "Enter your admin code",
      err: false,
      errText: "",
      className: "col-12",
    },
  ]);
  useEffect(() => {
    if (!logoUrl) {
      dispatch(getLogo(subdomain));
    }
  }, [!logoUrl]);

  // david
  useEffect(() => {
    if (createdUser) {
      setEmailData(createdUser);
    }
  }, [createdUser.adminEmail, createdUser.password]);

  const handleFields = (e, i, key) => {
    const obj = { ...editableData };
    const arr = [...fieldsList];
    const { value } = e.target;
    const len = value.length;
    if (len < 31) {
      obj[key] = value;
    }
    if (arr[i].err) {
      arr[i].err = false;
      arr[i].errText = "";
    }
    setEditableData(obj);
    setFieldsList(arr);
  };

  const isValid = () => {
    const arr = [...fieldsList];
    const { code } = editableData || {};
    const handleErr = (i, msg) => {
      arr[i].err = true;
      arr[i].errText = msg;
    };

    if (!code) {
      handleErr(0, "Code is required.");
    } else if (code.length < 6) {
      handleErr(0, "Code must be 6 digits long");
    }
    setFieldsList(arr);
    if (!code || code.length < 6) {
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (isValid()) {
      const { code } = editableData || {};
      const data = {
        ...emailData,
        accessCode: code,
        subdomain,
      };
      if (code) {
        dispatch(adminVerificationAccessCode(data, navigate, true));
      }
    }
  };

  const handleEmailText = (i) => {
    let data = {};
    if (i === "email") {
      setEmailAndPhoneMessage("email");      
    } else if (i === "phone") {
      if (isSmsSent) {
        setError({
          toastOpenClose: true,
          errorMsg: "Please wait 10 sec before attempting again.",
          type: "danger",
        });
        return;
      }
      setIsSmsSent(true);
      setTimeout(() => {
        setIsSmsSent(false);
      }, 10000);
      setEmailAndPhoneMessage("phone");
    }
    data = {
      adminEmail: emailData.adminEmail,
      _id: emailData.id,
      sendBy: i,
      subdomain,
    };
    dispatch(getAdminLoginAuthenticateCode(data));
  };

  const handleKeyPress = (e, key) => {
    if (e.key === "Enter") {
      if (key === 0) {
        handleRegister(e);
      }
    }
  };
  return (
    <>
      <div className="wrapper">
        <Helmet style={[{ cssText: `${globalCustomCSSWithTheme}` }]} />
        <div className="sos-card-login sos-card">
          <div className="logo w-100 d-flex justify-content-center">
            <img
              src={logoUrl ? logoUrl : "/images/logo.png"}
              className="img-fluid"
              alt="Logo"
            />
          </div>
          <div className="sos-content">
            <div>
              <span className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-underline">
                {`You should have received a code sent to your ${emailAndPhoneMessage}. Please enter
                that code into the field below.`}
              </span>
              <form>
                {map(fieldsList, (i, index) => {
                  return (
                    <div key={index}>
                      <div className="form-group" key={index}>
                        <label
                          htmlFor="exampleInputText"
                          className="form-label"
                        >
                          {i.title}
                        </label>
                        <input
                          type={i.type}
                          className="form-control"
                          id="codeInputText"
                          aria-describedby="codeHelp"
                          placeholder={i.placeholder}
                          onChange={(e) => handleFields(e, index, i.key)}
                          onKeyPress={(e) => handleKeyPress(e, 0)}
                        />
                        {i && i.err && (
                          <Toast
                            isOpen={i.err}
                            showClose={false}
                            msg={i.errText}
                            type={"danger"}
                            dur={3000}
                          />
                        )}
                      </div>
                      <div className="d-flex mt-3">
                        <div
                          className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none clickable"
                          onClick={() => handleEmailText("email")}
                        >
                          Send by email
                        </div>

                        <div
                          className="text-cerulean d-block mb-4 text-cerulean-position text-decoration-none clickable"
                          onClick={() => handleEmailText("phone")}
                        >
                          Send by text
                        </div>
                      </div>
                    </div>
                  );
                })}
              </form>
              {error.toastOpenClose && (
                  <div data-cy="general-code-error">
                    <Toast
                        isOpen={error.toastOpenClose}
                        showClose={false}
                        msg={error.errorMsg}
                        type={error.type}
                    />
                  </div>
              )}
              {isAdminLoginCodeSuccess && (
                <Toast
                  isOpen={true}
                  showClose={false}
                  msg={`Code sent. Please check your ${emailAndPhoneMessage}.`}
                  type={"success"}
                  dur={5000}
                />
              )}
            </div>
            <div className="d-flex justify-content-end submit-btn">
              <button
                type="button"
                className="sos-custom-btn sos-custom-btn-css"
                onClick={(e) => handleRegister(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAccessCode;
