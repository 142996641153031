import AdminRegistration from "./Pages/AdminRegistration";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Payments from "./Pages/Payments";
import LoginWithToken from "./Pages/LoginWithToken";
import ForgotPassword from "./Pages/ForgotPassword";
import UpdatePassword from "./Pages/UpdatePassword";
import Users from "./Pages/AdminDashboard/PayersTable.js";
// import Transaction from "./Pages/AdminDashboard/Transaction";
// import Transaction from "./Pages/AdminDashboard/TransactionsTable";
import UpdateEmailRequest from "./Pages/UpdateEmailRequest";
import UpdateEmail from "./Pages/UpdateEmail";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AdminHelloTest from "./Pages/AdminHelloTest/index.js";
import AdminLogin from "./Pages/AdminLogin";
import AdminAccessCode from "./Pages/AdminAccessCode";
import AccessCode from "./Pages/AccessCode";
import SendLinkEmail from "./Pages/SendLinkAdmin";
import UpdatePasswordAdmin from "./Pages/UpdatePasswordAdmin";
import LoginAdminWithToken from "./Pages/LoginAdminWithToken";
import Support from "./Pages/Suppoter";
import VerifyAdminEmailChange from "./Pages/VerifyAdminEmailChange";

const redirectUrl =
  window.location.origin === "https://www.sosoftpay.dev" ||
  window.location.origin === "https://sosoftpay.dev" ||
  window.location.origin === "https://sosoftpay.com" ||
  window.location.origin === "https://www.sosoftpay.com";

const routes = [
  {
    exact: true,
    path: "/login",
    element: <Login />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/loginAdmin/:userLoginToken",
    element: <LoginAdminWithToken />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/register",
    element: <Registration />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminRegistration",
    element: <AdminRegistration />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/home",
    element: <Dashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/payments",
    element: <Payments />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/loginWithToken/:userToken",
    element: <LoginWithToken />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/accessCode",
    element: <AccessCode />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminAccessCode",
    element: <AdminAccessCode />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/",
    element: redirectUrl ? <Support /> : <Login />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/forgotPassword",
    element: <ForgotPassword />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/updatepassword/:userToken",
    element: <UpdatePassword />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/updatepasswordAdmin/:tokenHash",
    element: <UpdatePasswordAdmin />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/updateEmailRequest",
    element: <UpdateEmailRequest />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/updateEmail/:updateEmailTokenHash",
    element: <UpdateEmail />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/updateEmailRequest",
    element: <UpdateEmailRequest />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/updateEmail/:updateEmailTokenHash",
    element: <UpdateEmail />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/users",
    element: <Users />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  // {
  //   exact: true,
  //   path: "/transaction",
  //   element: <Transaction />,
  //   isProtected: true,
  //   isAuthPage: false,
  //   isProtectedLayout: false,
  // },
  {
    exact: true,
    path: "/adminDashboard",
    element: <AdminDashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminhellotest/:debugurl",
    element: <AdminHelloTest />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminDashboard/allAdmins",
    element: <AdminDashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminDashboard/settings",
    element: <AdminDashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminDashboard/transactions",
    element: <AdminDashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
{
    exact: true,
    path: "/adminDashboard/allPayers",
    element: <AdminDashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/adminDashboard/payments",
    element: <AdminDashboard />,
    isProtected: true,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/admin",
    element: <AdminLogin />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },

  {
    exact: true,
    path: "/sendlink",
    element: <SendLinkEmail />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/verifyAdminEmailChange/:tokenHash",
    element: <VerifyAdminEmailChange />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
  {
    exact: true,
    path: "/verifyAdminEmailChangeResult",
    element: <VerifyAdminEmailChange />,
    isProtected: false,
    isAuthPage: false,
    isProtectedLayout: false,
  },
];

export default routes;
