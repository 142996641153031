import React from "react";
import routes from "./routes";
import {Routes, Route, useRoutes, useLocation} from "react-router-dom";
import {withProtectedLayout} from "./withProtectedLayout";
import ProtectedRoute from "./Components/ProtectedRoute";
import ProtectedAdminRoute from "./Components/ProtectedAdminRoute";
import AuthRoute from "./Components/AuthRoute";
import config from "./config";
import ClearCache from "react-clear-cache";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Register from "./Pages/Registration";

import AdminLogin from "./Pages/AdminLogin";
import VerifyAdminEmailChange from "./Pages/VerifyAdminEmailChange";
import AdminAccessCode from "./Pages/AdminAccessCode";
// import AdminSwipeCreditCard from "./Pages/AdminSwipeCreditCard";
import Transaction from "./Pages/AdminDashboard/Transaction";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AdminRegistration from "./Pages/AdminRegistration";
import Payments from "./Pages/Payments";
import LoginWithToken from "./Pages/LoginWithToken";
import ForgotPassword from "./Pages/ForgotPassword";
import UpdatePassword from "./Pages/UpdatePassword";
import Users from "./Pages/AdminDashboard/PayersTable.js";
import UpdateEmailRequest from "./Pages/UpdateEmailRequest";
import UpdateEmail from "./Pages/UpdateEmail";
import AdminHelloTest from "./Pages/AdminHelloTest/index.js";
import AccessCode from "./Pages/AccessCode";
import SendLinkEmail from "./Pages/SendLinkAdmin";
import UpdatePasswordAdmin from "./Pages/UpdatePasswordAdmin";
import LoginAdminWithToken from "./Pages/LoginAdminWithToken";
import Support from "./Pages/Suppoter";
import LogRocket from "logrocket";
import {ViewAppointment} from "./Refactor/Appointment/View/view-appointment";
import Forms from "./Pages/AdminDashboard/Forms";


const getMetaJson = () => {
    return (
        <ClearCache auto>
            {({isLatestVersion, emptyCacheStorage}) => (
                <div>
                    {!isLatestVersion && (
                        <p className="version-btn">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    emptyCacheStorage();
                                }}
                            >
                                Update version
                            </a>
                        </p>
                    )}
                </div>
            )}
        </ClearCache>
    );
};


function ProtectedRoutes() {
    const protectedRoutes = useRoutes([
        {
            path: "/home",
            element: <Dashboard/>,
            isProtected: true,
            isAuthPage: false,
            isProtectedLayout: false,
        },
    ]);
}

const redirectUrl =
    window.location.origin === "https://www.sosoftpay.dev" ||
    window.location.origin === "https://sosoftpay.dev" ||
    window.location.origin === "https://sosoftpay.com" ||
    window.location.origin === "https://www.sosoftpay.com";


function RoutesReactRouter6() {
    const routes = [{path: 'home', element: < ProtectedRoute> <Dashboard/></ProtectedRoute>},
        // { path: 'Payments', element: < ProtectedRoute > <Payments /></ProtectedRoute> },
        // { path: 'creditcard', element: <AdminSwipeCreditCard /> },
        {path: 'payments', element: < ProtectedRoute> <Payments/></ProtectedRoute>},
        {path: 'admin', element: <AdminLogin/>},
        {path: 'adminDashboard', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'adminDashboard/settings', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'adminDashboard/allAdmins', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'adminDashboard/transactions', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'adminDashboard/forms', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'adminDashboard/allPayers', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'adminDashboard/payments', element: < ProtectedAdminRoute> <AdminDashboard/></ProtectedAdminRoute>},
        {path: 'transaction', element: < ProtectedAdminRoute> <Transaction/></ProtectedAdminRoute>},
        {path: 'users', element: < ProtectedAdminRoute> <Users/></ProtectedAdminRoute>},
        {path: 'login', element: <Login/>},
        {path: 'register', element: <Register/>},
        {path: 'verifyAdminEmailChange/:tokenHash', element: <VerifyAdminEmailChange/>},
        {path: 'verifyAdminEmailChangeResult', element: <VerifyAdminEmailChange/>},
        {path: 'adminAccessCode', element: <AdminAccessCode/>},
        {path: 'loginAdmin/:userLoginToken', element: <LoginAdminWithToken/>},
        {path: 'adminRegistration', element: <AdminRegistration/>},
        {path: 'loginWithToken/:userToken', element: <LoginWithToken/>},
        {path: 'accessCode', element: <AccessCode/>},
        {path: 'adminAccessCode', element: <AdminAccessCode/>},
        {path: 'forgotPassword', element: <ForgotPassword/>},
        {path: 'updatepassword/:userToken', element: <UpdatePassword/>},
        {path: 'updatepasswordAdmin/:tokenHash', element: <UpdatePasswordAdmin/>},
        {path: 'updateEmailRequest', element: <UpdateEmailRequest/>},
        {path: 'updateEmail/:updateEmailTokenHash', element: <UpdateEmail/>},
        {path: 'updateEmailRequest', element: <UpdateEmailRequest/>},
        {path: 'updateEmail/:updateEmailTokenHash', element: <UpdateEmail/>},
        {path: 'sendlink', element: <SendLinkEmail/>},
        {path: 'appointment', element: <ViewAppointment/>},
        {path: 'adminhellotest/:debugurl', element: <AdminHelloTest/>}];
    let allTheRoutes;
    if (config.APP_TYPE === "local") {
        allTheRoutes = [{path: '/', element: <Support/>}, {path: 'adminDashboard', element: <AdminDashboard/>}, {
            path: ':subdomain/',
            children: routes
        }];
    } else {
        allTheRoutes = [{path: '/', element: (redirectUrl ? <Support/> : <Login/>)},
            ...routes];
    }

    return useRoutes(allTheRoutes);
}

LogRocket.init('xgkzd1/sosoft');

const App = () => {
    const loc = useLocation();
    return (
        <div>
            <RoutesReactRouter6/>
            {getMetaJson()}
        </div>
    );
};

export default App;
